import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import {
  Fab,
  Card,
  CardContent,
  Fade,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
} from '@material-ui/core'
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import { makeStyles } from '@material-ui/core/styles'
import {
  Add,
  Poll,
  Person,
  Business,
  Comment,
  Description,
  ViewCarousel,
  PeopleRounded,
  LibraryAddCheckRounded,
  ReceiptRounded,
  InsertDriveFileRounded,
  LiveHelp
} from '@material-ui/icons'
import Sidebar from './Sidebar'
import TopBarContainer from '../containers/TopBarContainer'
import '../styles/Table/table.css'


const Layout = ({ title, head, children }) => {
  const classes = useStyles()
  const [drawerIsOpen, setDrawerIsOpen] = React.useState()
  const [addButton, setAddButton] = React.useState()
  const matches = useMediaQuery('(min-width:960px)')

  const addLinks = [
    {
      label: 'Add ESO',
      url: '/organizations/new',
      icon: <Business />,
    },
    {
      label: 'Add News',
      url: '/newsfeed/new',
      icon: <Comment />,
    },
    {
      label: 'Add Resource',
      url: '/resources/new',
      icon: <Description />,
    },
    {
      label: 'Add User',
      url: '/users/new',
      icon: <Person />,
    },
    {
      label: 'Add Survey',
      url: '/survey/new',
      icon: <Poll />,
    },
    {
      label: 'Add Carousel',
      url: '/carousel/new',
      icon: <ViewCarousel />,
    },
    {
      label: 'Add Cohort',
      url: '/cohort/new',
      icon: <PeopleRounded   />,
    },
    {
      label: 'Add Blog',
      url: '/blog/new',
      icon: <LibraryAddCheckRounded />,
    },{
      label: 'Add News-Letter',
      url: '/newsletter/new',
      icon: <ReceiptRounded   />,
    },
  {
    label: 'Add Video Document',
    url: '/labDocument/new',
    icon: <InsertDriveFileRounded />,
  },
  {
    label: 'Add Chat-bot Q&A',
    url: '/chatbot/new',
    icon: <LiveHelp />,
  },

  {
    label: 'Add Lab Document',
    url: '/fileDocument/new',
    icon: <DescriptionRoundedIcon />,
  },

  ]


  return (
    <div className={classes.root}>
      <TopBarContainer
        drawerIsOpen={drawerIsOpen}
        setDrawerIsOpen={value => setDrawerIsOpen(value)}
        title={title}
      />
      <div className={classes.main}>
        {(drawerIsOpen || matches) &&
          <div className={classes.sidebar}>
            <Sidebar />
          </div>
        }
        <div className={`${classes.content} layout-content`}>
          {head &&
            <Card className={`${classes.card}`}>
              <CardContent className={classes.cardContent}>
                {head}
              </CardContent>
            </Card>
          }
          {children &&
            <Card className={`${classes.card} card-root-table`}>
              <CardContent className={classes.cardContent}>
                {children}
              </CardContent>
            </Card>
          }
        </div>
        <div className={classes.addButton}>
          <Fab
            color='secondary'
            aria-label='Add'
            onClick={e => setAddButton(e.currentTarget)}
            aria-owns={addButton ? 'plus-menu' : null}
            aria-haspopup='true'
            className={classes.addButtonTrigger}
          ><Add /></Fab>
          <Menu
            id='plus-menu'
            anchorEl={addButton}
            open={Boolean(addButton)}
            onClose={() => setAddButton(null)}
            TransitionComponent={Fade}
          >
            {addLinks.map((item, index) =>
              <MenuItem component={Link} to={item.url} key={index} >
                <ListItemIcon>{item.icon}</ListItemIcon>
                <ListItemText
                  className={classes.addButtonText}
                  primary={item.label}
                />
              </MenuItem>
            )}
          </Menu>
        </div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  title: PropTypes.string,
}

const useStyles = makeStyles(theme => ({
  root: {
    minHeight: '100vh',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flexGrow: 1,
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      alignItems: 'stretch',
    },
  },
  sidebar: {
    [theme.breakpoints.only('sm')]: {
      flexBasis: theme.sidebarWidth.sm,
      minWidth: theme.sidebarWidth.sm,
    },
    [theme.breakpoints.up('md')]: {
      flexBasis: theme.sidebarWidth.md,
      minWidth: theme.sidebarWidth.md,
    },
  },
  content: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(5),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    flexGrow: 1,
    [theme.breakpoints.only('xs')]: {
      paddingRight: theme.spacing(3),
    },
  },
  card: {
    marginBottom: theme.spacing(3),
    maxWidth: '100%',
  },
  cardContent: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(4),
    paddingBottom: theme.spacing(3),
    paddingLeft: theme.spacing(4),
    borderBottomWidth: 1,
    borderBottomColor: theme.palette.grey[300],
    borderBottomStyle: 'solid',
    '&:last-child': {
      paddingBottom: theme.spacing(5),
    },
  },
  addButton: {
    position: 'fixed',
    right: theme.spacing(2),
    bottom: theme.spacing(5),
  },
  addButtonTrigger: {
    color: 'white',
  },
  addButtonText: {
    minWidth: 200,
  },
}))

export default Layout
