import React, { useCallback, useMemo, useState } from "react";
import "./LabDocumentLibraryPage.css";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import Pagination from "../../components/Pagination";
import LabDocumentList from "../../components/LabDocumentList/LabDocumentList";
import { useSnackbar } from "notistack";
import history from "../../history";
import LabDocuemntFilterForm from "../../forms/LabDocumentFilterForm/LabDocuemntFilterForm";
import { countLabDocsQuery, getLabDocumentsData } from "../../api/_queries";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { deleteLabDocumentMutation } from "../../api/_mutations";
import ApolloCacheUpdater from "apollo-cache-updater";



const LabDocumentLibraryPage = () => {
  const blogsList = [
    {
      id: "1",
      title: "xyz",
      description: "dddwdwwdd",
      tag: "technology"
    },
    {
      id: "2",
      title: "xyz",
      description: "dddwdwwdd",
      tag: "technology"
    }
  ];
  const { enqueueSnackbar } = useSnackbar();

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sortData, setSortData] = useState(["_id:desc"]);


  const parseFilters = useMemo(() => {
    const { title, services} = filters;
    if (title) {
      var searchKeyword = title
        .replace(/\\/g, "\\\\\\\\")
        .replace(/\!/g, "\\\\!")
        .replace(/\@/g, "\\\\@")
        .replace(/\#/g, "\\\\#")
        .replace(/\$/g, "\\\\\\$")
        .replace(/\%/g, "\\\\%")
        .replace(/\^/g, "\\\\^")
        .replace(/\&/g, "\\\\&")
        .replace(/\*/g, "\\\\*")
        .replace(/\)/g, "\\\\)")
        .replace(/\(/g, "\\\\(")
        .replace(/\[/g, "\\\\[")
        .replace(/\]/g, "\\\\]")
        .replace(/\;/g, "\\\\;")
        .replace(/\{/g, "\\\\{")
        .replace(/\}/g, "\\\\}")
        .replace(/\?/g, "\\\\?")
        .replace(/\,/g, "\\\\,");
    }
    return {
      ...(title && { title_contains: searchKeyword }),
      ...(services && {services_contains:  services }),
     
    };
  }, [filters]);


  const [deleteLabDocument] = useMutation(deleteLabDocumentMutation, {})


  const {
    data: { getLabDocs: labDocumentList = [], countLabDocs: rowsInTotal } = {},
    loading,
    error
  } = useQuery(getLabDocumentsData, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters &&
          Object.keys(filters).length &&
          Object.keys(parseFilters).length
            ? { where: parseFilters }
            : {})
      },
      fetchPolicy:'no-cache'
    }
  });




 //Delete function:
 const handleDeleteCalllback = useCallback(async (id, name) => {
    deleteLabDocument({
      variables: {
        id,
      },
      refetchQueries: [{
        query: getLabDocumentsData,
        variables: {
          params: {
            limit: rowsPerPage,
            skip: page * rowsPerPage,
            ...(filters && Object.keys(filters).length
                ? { where: parseFilters }
                : {}
              )
          },
        }
      }],
      update: (proxy) => {
        const update = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getLabDocumentsData,countLabDocsQuery], // queries you want to automatically update
          searchVariables: {},
          operator: 'ANY',
          operation: 'REMOVE',
          mutationResult: { _id: id },
          ID: '_id',
        })
        if (update) enqueueSnackbar(`Lab Document ${name} has been deleted.`, { variant: 'success' })
      }
    }).then(() => { }).catch((e) => {
      enqueueSnackbar('Cannot delete Lab Document.', { variant: 'error' })
    })
  }, [])



    

  const handleRowPerChange = value => {
    setRowsPerPage(value);
  };

  const sortFilter = useCallback((value, key) => {
    if (value == "title") {
      setSortData([`title:${key}`]);
    } else {
      setSortData([`${value}:${key}`]);
    }
  }, []);

  const handleClear = useCallback(() => {
    setFilters({});
    history.push("/lab-document");
  }, [history]);

  const handleFiltersSubmit = useCallback(fields => {
    setPage(0);
    setFilters({ ...fields });
  }, []);
  return (
    <Authenticated>
      <Layout
        title="Video Document List"
        head={
          <LabDocuemntFilterForm
            handleClear={handleClear}
            callback={handleFiltersSubmit}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <LabDocumentList
            data={labDocumentList}
            sortFilter={sortFilter}
            deleteCallback={handleDeleteCalllback}
          />

          {rowsInTotal > rowsPerPage && (
            <Pagination
              page={page}
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              callback={newPage => setPage(newPage)}
              handleRowPerChange={handleRowPerChange}
            />
          )}
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default LabDocumentLibraryPage;
