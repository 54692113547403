import React, { useState } from "react";
import "./CohortForm.css";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import ApolloCacheUpdater from 'apollo-cache-updater'
import * as Yup from "yup";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField
} from "@material-ui/core";
import BUSINESS_SECTORS from "../../settings/enums/target-sectors.json";
import SERVICES from "../../settings/enums/categories.json";
import AFRICAN_ISO from "../../settings/enums/africa-iso-codes.json";
import history from "../../history";
import LANGUAGES from '../../settings/enums/languages.json'

import Spinner from "../../components/Spinner";
import MultiImageUpload from "../../components/MultiImageUpload";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import Wysiwyg from "../Wysiwyg";
import "./CohortForm.css"
import ImageUpload from "../../components/ImageUpload";
import { DeleteOutlineOutlined } from "@material-ui/icons";
import { _safeS3SignMutation } from "../../api";
import { useMutation } from "@apollo/react-hooks";
import { saveCohortAdminData } from "../../api/_mutations";
import { useSnackbar } from "notistack";
import maxFileSize from '../../settings/max-file-size.json'
import { graphqlError } from "../../utils";
import { countCohortQuery, getCohortsData } from "../../api/_queries";


const CohortForm = ({ data, languageIndex, languagesTotal }) => {
     
   const [safeS3Sign] = useMutation(_safeS3SignMutation)
    const [saveCohortAdmin, { loading }] = useMutation(saveCohortAdminData)
    const { maxSize } = maxFileSize
  const [changingLanguage, setChangingLanguage] = useState();
  const [fileUploading, setFileUploading] = React.useState();
  const [initialValues, setInitialValues] = React.useState(data);
const { enqueueSnackbar } = useSnackbar()
  const firstUpdate = React.useRef(true);


  console.log("data",initialValues)
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    if (data) {
      // setType(data.category)
      setInitialValues(data);
    }
    //   else setType(resourceTypeOptions[0].value)
  }, [data, firstUpdate]);
  React.useEffect(() => {
    // Set initial values again after changing current language.
    if (!firstUpdate.current) {
      const handleAsync = async () => {
        await setChangingLanguage(true);

        await setInitialValues({ ...data, ...initialValues });
        await setChangingLanguage(false);
      };
      handleAsync();
    }
  }, [languageIndex]);

  const handleSubmit = async fields => {
    {
      console.log(fields, "fields");
    }

    const dataObject = {
      ...fields,
      language: Object.keys(LANGUAGES)[languageIndex]
  
    };
    let allImages;
    if (Array.isArray(fields?.fileUrl)) {
      allImages = await fields?.fileUrl.reduce(async (arr, file) => {
        let attachmentUrl = file || null;
        if (attachmentUrl) {
          if (typeof attachmentUrl === "string")
            return [...(await arr), attachmentUrl];
          const { dataFile, name: filename } = file;
          const { size } = dataFile;
          if (size > maxSize) {
            // toast.error(translations.messagesNewMessageErrorFileBig)
            return null;
          }
          try {
            const response = await safeS3Sign({
              variables: {
                filename: filename,
                filetype: file.type
              }
            });
            const { signedRequest, url: bucketUrl } = response.data._safeSignS3;
            const options = {
              headers: {
                "Content-Type": dataFile.type
              }
            };
            try {
              setFileUploading(true);
              await fetch(signedRequest, {
                method: "PUT",
                headers: options.headers,
                body: dataFile
              });
              setFileUploading(false);
              attachmentUrl = bucketUrl;
            } catch (e) {
              attachmentUrl = null;
              console.log(e);
              setFileUploading(false);
            }
          } catch (e) {
            const errMsg = graphqlError(e, {
              // NOT_PERMITTED_FILE_TYPE: translations.accountSettingsErrorPasswordWrong,
              // NOT_PERMITTED_FILE_SIZE: translations.accountSettingsErrorPasswordWrong,
            });
            if (errMsg) {
              // toast.error(errMsg)
            } else {
              // toast.error('Cannot uplaod file(s)')
              console.log(e);
            }
            return [...(await arr)];
          }
          return [...(await arr), attachmentUrl];
        }
      }, []);
      if (allImages && allImages.length > 0) {
        dataObject.fileUrl = allImages;
      }
     

      if (Array.isArray(dataObject.Reviews)) {
        dataObject.Reviews = await dataObject.Reviews.reduce(async (accPromise, review) => {
          console.log(review,"review")
          const acc = await accPromise;
          let photoUrl = review.photo || null;
      
          if (photoUrl) {
            if (typeof photoUrl === "string") {
              console.log("Existing photo URL:", photoUrl);
              return [...acc, { ...review, photo: photoUrl }];
            }
      
            const { dataFile, name: filename } = review.photo;
            const { size } = dataFile;
      
            if (size > maxSize) {
              console.error("File size is too large");
              return [...acc, { ...review, photo: null }];
            }
      
            try {
              console.log("Requesting S3 signed URL for:", filename);
              const response = await safeS3Sign({
                variables: {
                  filename: filename,
                  filetype: review.photo.type,
                },
              });
      
              const { signedRequest, url: bucketUrl } = response.data._safeSignS3;
              console.log("Received S3 URL:", bucketUrl);
      
              const options = { headers: { "Content-Type": dataFile.type } };
      
              try {
                console.log("Uploading to S3...");
                await fetch(signedRequest, {
                  method: "PUT",
                  headers: options.headers,
                  body: dataFile,
                });
      
                photoUrl = bucketUrl;
                console.log("Upload successful! Updated photo URL:", photoUrl);
              } catch (uploadError) {
                console.error("Upload failed:", uploadError);
                photoUrl = null;
              }
            } catch (signError) {
              console.error("Error signing request:", signError);
              photoUrl = null;
            }
          }
      
          return [...acc, { ...review, photo: photoUrl }];
        }, Promise.resolve([]));
      
        console.log("Final Reviews Payload:", JSON.stringify(dataObject.Reviews, null, 2));
      }
      
      
      saveCohortAdmin({
        variables: {
          data: {
            ...(data && data._id) && { _id: data._id },
            ...dataObject
          }
        },
        update: (proxy, { data: { saveCohortAdmin = {} } }) => {
          if (!data || !data._id) {
            const mutationResult = saveCohortAdmin
            ApolloCacheUpdater({
              proxy,
              queriesToUpdate: [getCohortsData,countCohortQuery],
              searchVariables: {
                skip: 0
              },
              mutationResult: {
                ...mutationResult,
              },
              ID: '_id',
            })
          }
        },
      })
        .then(() => {
          enqueueSnackbar("The Cohort has been saved.", {
            variant: "success"
          });
          
          history.replace("/cohortList");
        })
        .catch(e => {
          enqueueSnackbar("There was an error saving the cohort", {
            variant: "error"
          });
        });
    }
  };

  const formFields = {
    sectors: initialValues?.sectors || [],
    services: initialValues?.services || [],
    country: initialValues?.country || "",
    fileUrl: initialValues?.fileUrl?.map(file => file.url) || [],
    fb_url: initialValues?.fb_url || "",
    x_url: initialValues?.x_url || "",
    in_url: initialValues?.in_url || "",
    email: initialValues?.email || "",
    phone: initialValues?.phone || "",
    name: initialValues?.name || [],
    year: initialValues?.year || new Date(),
    num_participants: initialValues?.num_participants || "",
    details: initialValues?.details || "",
    impact_metrics: initialValues?.impact_metrics || "",
    disaggregations: initialValues?.disaggregations || "",
    Reviews:initialValues?.Reviews?.map(({ __typename, ...rest }) => rest) || [],

      // fromDate:initialValues?.fromDate || "",

      fromDate: initialValues?.fromDate ? new Date(initialValues.fromDate).toISOString().split("T")[0] : "",

      toDate:initialValues?.toDate ? new Date(initialValues.toDate).toISOString().split("T")[0] : "",
    name_eso:initialValues?.name_eso || ""
  
  };

  let validationSchema = Yup.object().shape({
    sectors: Yup.array().min(1, "This field is required."),
    services: Yup.array().min(1, "This field is required."),
    country: Yup.string().required("This field is required."),
    fileUrl: Yup.array().min(1, "This field is required."),
    fb_url: Yup.string()
      .matches(/^(https?:\/\/)/, "URL should start with http:// or https://")
      .matches(/^(https|http):\/\/?[a-z0-9]/, "This is not a valid URL.")
      .required("This field is required."),
    x_url: Yup.string()
      .matches(/^(https?:\/\/)/, "URL should start with http:// or https://")
      .matches(/^(https|http):\/\/?[a-z0-9]/, "This is not a valid URL.")
      .required("This field is required."),
    in_url: Yup.string()
      .matches(/^(https?:\/\/)/, "URL should start with http:// or https://")
      .matches(/^(https|http):\/\/?[a-z0-9]/, "This is not a valid URL.")
      .required("This field is required."),
    email: Yup.string()
      .email("This is not a valid email address.")
      .required("This field is required."),
    phone:          Yup.string()
            // .matches(
            //   // /^[+]?[(]?[0-9]{1,4}[)]?[-\s./0-9]{7,14}$/,
            //   "Invalid phone number format"
            // )
            .matches(
              /^(?:\d{10}|\d{11}|\d{15})$/,
              // /^[0-9]{1,4}[-\s./0-9]{7,14}$/,
              
              "Invalid phone number format"
            )
            .test(
              "no-plus-sign",
              "Invalid phone number format: '+' is not allowed",
              (value) => !value || !value.includes("+")
            )
            .required("Phone number is required"),
    name: Yup.array().of(Yup.string()).test('name-ele',
          'This field is required',
          function (value) {
            if (value && value[languageIndex]) return true
            else return false
          }),
    year: Yup.date()
      .required("Year is required") // Make the field required
      .nullable(),
    num_participants: Yup.string()
      .matches(/^\d*$/, "Only numbers are allowed") // Regex to allow only numbers
      .required("Number of participants is required"),

    details: Yup.string()
    .max(300, "Details cannot exceed 300 characters")
      .required("Details are required"),
    impact_metrics: Yup.string().required("Impact metrics are required"),
    disaggregations: Yup.string().required("Disaggregations are required"),
    fromDate: Yup.date()
    .required("From date is required") // Make the field required
    .nullable(),
        toDate: Yup.date()
        .required("To date is required") // Make the field required
        .nullable(),
    name_eso: Yup.string().required("Disaggregations are required"),
    Reviews: Yup.array().of(
      Yup.object().shape({
        review: Yup.string()
          .max(300, "Review must be at most 300 characters")
          .when("$hasReviews", {
            is: true, // Apply validation only if there are reviews
            then: Yup.string().required("Review is required if a review exists"),
            otherwise: Yup.string().notRequired(), // No validation when array is empty
          }),
      })
    ),

    
  });

  return (
    <div>
      <Formik
      enableReinitialize
        initialValues={formFields}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, handleChange, errors }) => {
          return (
              <Form>
                {console.log(errors,"errors")}
              <Grid container spacing={6} justifyContent="space-between">
                <Grid
                  item
                  md={6}
                  lg={4}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <FormControl fullWidth>
                    <InputLabel id="demo-mutiple-name-label">
                      Sectors <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      multiple
                      name="sectors"
                      onChange={handleChange}
                      value={values?.sectors || []}
                      renderValue={selected => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                          {selected.map(value => (
                            <Chip
                              key={value}
                              label={BUSINESS_SECTORS[value]?.toUpperCase()}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {Object.entries(BUSINESS_SECTORS).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="sectors">
                      {msg => (
                        <FormHelperText
                          className="form-error"
                          style={{ color: "red" }}
                        >
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel id="demo-mutiple-name-label">
                      Services <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      multiple
                      fullWidth
                      name="services"
                      label="Services offered"
                      onChange={handleChange}
                      value={values?.services || []}
                      renderValue={selected => (
                        <Box sx={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                          {selected.map(value => (
                            <Chip
                              key={value}
                              label={SERVICES[value]?.label?.toUpperCase()}
                            />
                          ))}
                        </Box>
                      )}
                    >
                      {Object.entries(SERVICES).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value?.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="services">
                      {msg => (
                        <FormHelperText
                          className="form-error"
                          style={{ color: "red" }}
                        >
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <InputLabel>
                      Country <span style={{ color: "red" }}>*</span>
                    </InputLabel>
                    <Select
                      name="country"
                      value={values.country}
                      onChange={handleChange}
                    >
                      {Object.entries(AFRICAN_ISO).map(([key, value]) => (
                        <MenuItem value={`${key}__${value}`} key={key}>
                          {key}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="country">
                      {msg => (
                        <FormHelperText
                          className="form-error"
                          style={{ color: "red" }}
                        >
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <Box style={{ marginBottom: "5%", marginTop: "5%" }}>
                    <label>
                      Upload image <span style={{ color: "red" }}>*</span>
                    </label>
                    <MultiImageUpload
                      name={"fileUrl"}
                      value={values?.fileUrl}
                      setValue={setFieldValue}
                    />
                    <ErrorMessage name="fileUrl">
                      {msg => (
                        <FormHelperText
                          className="form-error"
                          style={{ color: "red" }}
                        >
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </Box>
                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          Facebook URL <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name={`fb_url`}
                      value={values?.fb_url}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="fb_url">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          Twitter URL <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name={`x_url`}
                      value={values?.x_url}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="x_url">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          LinkedIN URL <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name={`in_url`}
                      value={values?.in_url}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="in_url">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      name="email"
                      label={
                        <span>
                          Email address <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="email"
                      onChange={handleChange}
                      value={values && values.email ? values.email : ""}
                      fullWidth
                    />
                    <ErrorMessage name="email">
                      {msg => (
                        <div className="form-error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth>
                   
                    <TextField
                      name="phone"
                      label={
                        <span>
                          Phone <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      value={values?.phone}
                      onChange={handleChange}
                      fullWidth
                    />
                    <ErrorMessage name="phone">
                      {msg => (
                        <div className="form-error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  md={6}
                  lg={7}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          Name 
                        </span>
                      }
                      type="text"
                      name={`name[${languageIndex}]`}
                      value={values?.name[languageIndex] || ""}
                      

                      onChange={handleChange}
                    />
                    <ErrorMessage name="name">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                   <FormControl fullWidth>
                  
                   <label>
                     From Date <span style={{ color: "red" }}>*</span>
                    </label>
                                <TextField
                                  fullWidth
                                  type="date"
                                  name='fromDate'
                                  onChange={handleChange}
                                  value={values.fromDate}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                // inputProps={{ max: new Date().toISOString().slice(0, 10) }}
                                />
                                <ErrorMessage name="fromDate">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                              </FormControl>
                              <FormControl fullWidth>
                  
                              <label>
                     To Date <span style={{ color: "red" }}>*</span>
                    </label>
                                <TextField
                                  fullWidth
                                  type="date"
                                  name='toDate'
                                  onChange={handleChange}
                                  value={values.toDate}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                                <ErrorMessage name="toDate">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                              </FormControl>
                  <FormControl fullWidth>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="year-picker-dialog"
                        label="Year"
                        views={["year"]} // Only show year view
                        value={values?.year}
                        onChange={date => {
                          if (date instanceof Date && !isNaN(date)) {
                            setFieldValue("year", date.getFullYear().toString()); // Store only the year as a string
                          } else {
                            setFieldValue("year", ""); // Reset if the date is invalid
                          }
                        }}
                        
                        // onChange={date => setFieldValue("year", date)}
                        format="yyyy" // Display only the year
                        KeyboardButtonProps={{
                          "aria-label": "change year"
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </FormControl>
                   <FormControl fullWidth>
                   <TextField
                      label={
                        <span>
                          Name of ESO
                        </span>
                      }
                      type="text"
                      name={`name_eso`}
                      value={values?.name_eso}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="name_eso">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                    </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      name={"num_participants"}
                      label={
                        <span>
                          Number of participants{" "}
                        </span>
                      }
                      value={values?.num_participants}
                      onChange={(event) => {
                        const { name, value } = event.target;
                        setFieldValue(name, value ? Number(value) : ""); // Convert to number
                      }}
                    />
                    <ErrorMessage name={"num_participants"}>
                      {msg => (
                        <div className="form-error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={4}
                      maxRows={4}
                      placeholder="Add Details"
                      name="details"
                      value={values?.details}
                      onChange={handleChange}
                    />
                    <ErrorMessage name={"details"}>
                      {msg => (
                        <div className="form-error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth style={{ marginTop: "5px" }}>
                    <Wysiwyg
                      name={`impact_metrics`}
                      label="Imapct Metrics"
                      placeholder={"Imapct Metrics"}
                      setValue={setFieldValue}
                      value={values?.impact_metrics}
                    />
                    <ErrorMessage name={"impact_metrics"}>
                      {msg => (
                        <div className="form-error" style={{ color: "red",paddingTop:"50px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth style={{ marginTop: "50px" }}>
                    <Wysiwyg
                      name={`disaggregations`}
                      label="Disaggregations"
                      placeholder={"Disaggregations"}
                      setValue={setFieldValue}
                      value={values?.disaggregations}
                    />
                    <ErrorMessage name={"disaggregations"}>
                      {msg => (
                        <div className="form-error" style={{ color: "red",paddingTop:"50px" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FieldArray
                  
              name="Reviews"
              render={(arrayHelpers) => (
                <div className="add-review-div">
                      <button
                      style={{margintTop:"50px",cursor: "pointer"}}
                    type="button"
                   
                    onClick={() =>
                      arrayHelpers.push({
                        name: "",
                        position: "",
                        review: "",
                        photo: null,
                      })
                    }
                  >
                    Add Review
                  </button>
                  {values.Reviews.map((review, index) => (
                    <div
                      key={index}
                      className="p-4 border rounded mb-4 space-y-2 add-review-main-div"
                    >
                      <FormControl fullWidth>
                        <TextField
                          label={<span>Name</span>}
                          type="text"
                          value={values.Reviews[index].name}
                          name={`Reviews[${index}].name`}
                          onChange={handleChange}
                        />
                        <ErrorMessage name={`Reviews[${index}].name`}>
                          {msg => (
                            <FormHelperText style={{ color: "red" }}>
                              {msg}
                            </FormHelperText>
                          )}
                        </ErrorMessage>
                      </FormControl>

                      <FormControl fullWidth>
                        <TextField
                          label={<span>Position</span>}
                          type="text"
                          name={`Reviews[${index}].position`}
                          onChange={handleChange}
                          value={values.Reviews[index].position}
                        />
                        <ErrorMessage name={`Reviews[${index}].position`}>
                          {msg => (
                            <FormHelperText style={{ color: "red" }}>
                              {msg}
                            </FormHelperText>
                          )}
                        </ErrorMessage>
                      </FormControl>

                      <FormControl fullWidth>
                        <TextareaAutosize
                          aria-label="minimum height"
                          minRows={4}
                          maxRows={4}
                          placeholder="Add Review"
                          name={`Reviews[${index}].review`}
                          //   value={values?.details}
                          onChange={handleChange}
                          value={values.Reviews[index].review}
                        />
                        <ErrorMessage name={`Reviews[${index}].review`}>
                          {msg => (
                            <FormHelperText style={{ color: "red" }}>
                              {msg}
                            </FormHelperText>
                          )}
                        </ErrorMessage>
                      </FormControl>

                      <div>
                        <label className="block text-sm font-medium">
                          Photo
                        </label>
                        <ImageUpload
                          name={`Reviews[${index}].photo`}
                          value={values.Reviews[index].photo} // Pass the current value from Formik
                          setFieldValue={setFieldValue}
                          onChange={e =>
                            setFieldValue(
                              `Reviews[${index}].photo`,
                              e.currentTarget.files[0]
                            )
                          }
                        />
                      </div>

                      <button
                        className="remove-button"
                        onClick={() => arrayHelpers.remove(index)} // Remove this set
                      >
                        <DeleteOutlineOutlined />
                      </button>
                    </div>
                  ))}               
                </div>
              )}
            />
                </Grid>
              </Grid>

              <Grid container spacing={5} justifyContent="flex-end">
                <Grid container item sm={2} xs={3} justifyContent="flex-end">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid container item sm={2} xs={3} justifyContent="flex-end">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {loading ?
                <Spinner color='secondary' size={25} /> : "Save"}
                    
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default CohortForm;
