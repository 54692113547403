import React, { useState, useCallback, useEffect } from "react";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField
} from "@material-ui/core";
import SERVICES from "../../settings/enums/categories.json";
import history from "../../history";
import Spinner from "../../components/Spinner";
import Wysiwyg from "../Wysiwyg";
import "./FileDocumentForm.css";
import ImageUpload from "../../components/ImageUpload";
import { DeleteOutlineOutlined } from "@material-ui/icons";
import { _safeS3SignMutation } from "../../api";
import { useMutation, useQuery, useLazyQuery } from "@apollo/react-hooks";
import { useSnackbar } from "notistack";
import maxFileSize from "../../settings/max-file-size.json";
import { saveFileLabDocData } from "../../api/_mutations";
import { graphqlError } from "../../utils";
import { countFileLabDocsQuery, getFileLabDocumentsData } from "../../api/_queries";
import ApolloCacheUpdater from "apollo-cache-updater";


const FileDocumentForm = ({ data, languageIndex, languagesTotal }) => {
  const [safeS3Sign,{ loading: s3Loading}] = useMutation(_safeS3SignMutation);
    const [saveFileLabDoc, { loading }] = useMutation(saveFileLabDocData);
  const { maxSize } = maxFileSize;
  const [changingLanguage, setChangingLanguage] = useState();
  const [fileUploading, setFileUploading] = React.useState();
  const [initialValues, setInitialValues] = React.useState(data);
     const [uploadLoading, setUploadLoading] = useState(false)
  

  const { enqueueSnackbar } = useSnackbar();
  const firstUpdate = React.useRef(true);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    if (data) {
      // setType(data.category)
      setInitialValues(data);
    }
    //   else setType(resourceTypeOptions[0].value)
  }, [data, firstUpdate]);

  useEffect(() => {
    // Set initial values again after changing current language.
    if (!firstUpdate.current) {
      const handleAsync = async () => {
        await setChangingLanguage(true);

        await setInitialValues({ ...data, ...initialValues });
        await setChangingLanguage(false);
      };
      handleAsync();
    }
  }, [languageIndex]);

 

  const formFields = {
    services: initialValues?.services || [],
    // documents: initialValues?.documents?.map(({ __typename, ...rest }) => rest) || [],
    documents: initialValues?.documents?.map(({ fileUrl }) => ({
      name: fileUrl.split("/").pop(), // Extract filename from URL
      url: fileUrl // Store URL separately
    })) || [],
    image: initialValues?.image || "",
    title: initialValues?.title || [],
    uri: initialValues?.uri || "",
    abstract: initialValues?.abstract || [],
    citation: initialValues?.citation || [],
    authors: initialValues?.authors || [],
    // authors:
    //   initialValues?.authors?.map(({ __typename, ...rest }) => rest) || [],
    content: initialValues?.content || []
  };

  let validationSchema = Yup.object().shape({

        services: Yup.array()
          .min(1, "This field is required.")
          .nullable(),
    // services: Yup.array().min(1, "This field is required."),
    image: Yup.mixed()
      .test("fileSize", "Image file size must be less than 5MB", value => {
        if (typeof value === "object") {
          return value.size <= 5242880;
        }
        return true;
      })
      .required("Image file is required"),
    title: Yup.array()
      .of(Yup.string())
      .test("title-element", "This field is required", function(value) {
        if (value && value[languageIndex]) return true;
        else return false;
      }),

    documents: Yup.array()
      .min(1, "At least one file is required.")
      .required("This field is required."),
    uri: Yup.string()
      .matches(/^(https?:\/\/)/, "URL should start with http:// or https://")
      .matches(/^(https|http):\/\/?[a-z0-9]/, "This is not a valid URL.")
      ,

    abstract: Yup.array()
      .of(Yup.string())
      .test("name-ele", "Abstract is required", function(value) {
        if (value && value[languageIndex]) return true;
        else return false;
      }),
      authors: Yup.array()
    .of(Yup.string().required("Author name is required")) // Ensures each entry is not empty
    .min(1, "At least one author is required") // Ensures at least one author exists
    .required("Authors field is required"),

    content: Yup.array()
          .of(
            Yup.string().max(
              300,
              "Content field must not exceed 300 characters"
            )
          )

  });


  const handleSubmit = async fields => {
    try{
    const dataObject = {
      ...fields
    };

    if (fields.image) {
      const file = fields?.image;
      let attachmentUrl = file || null;
      if (attachmentUrl) {
        if (typeof attachmentUrl === "string") {
          dataObject.image = attachmentUrl;
          console.log(dataObject, "fffeef");
        } else {
          const { dataFile, name: filename } = file;
          const { size } = dataFile;
          if (size > maxSize) {
            return null;
          }

          try {
            setUploadLoading(true);
            const response = await safeS3Sign({
              variables: {
                filename,
                filetype: file.type
              }
            });
            const {
              signedRequest,
              url: bucketUrl
            } = response.data._safeSignS3;
            const options = {
              headers: {
                "Content-Type": file.type
              }
            };
            try {
              await fetch(signedRequest, {
                method: "PUT",
                headers: options.headers,
                body: dataFile
              });
              attachmentUrl = bucketUrl;
              setUploadLoading(false);
            } catch (e) {
              attachmentUrl = null;
              console.error(e);
              setUploadLoading(false);
            }
          } catch (e) {
            const errMsg = graphqlError(e, {
              // NOT_PERMITTED_FILE_TYPE: translations.accountSettingsErrorPasswordWrong,
              // NOT_PERMITTED_FILE_SIZE: translations.accountSettingsErrorPasswordWrong,
            });
            if (errMsg) {
              enqueueSnackbar.error(errMsg);
            } else {
              enqueueSnackbar.error("Cannot uplaod file(s)");
              console.error(e);
            }
          }
          dataObject.image = attachmentUrl;
        }
      }
    }

    
    let allFileDocuments;
    if (Array.isArray(fields?.documents)) {
      allFileDocuments = await fields?.documents.reduce(async (arr, file) => {
        let attachmentUrl = file || null;
        console.log(attachmentUrl, "fffeef");
        if (attachmentUrl) {
          if (attachmentUrl.url && typeof attachmentUrl.url === "string"){
            return [...(await arr), attachmentUrl.url];
          } else {
            const {  name: filename } = file;
         
            try {
              const response = await safeS3Sign({
                variables: {
                  filename: filename,
                  filetype: file.type
                }
              });
              const {
                signedRequest,
                url: bucketUrl
              } = response.data._safeSignS3;
              const options = {
                headers: {
                  "Content-Type": file.type
                }
              };
              try {
                setFileUploading(true);
                await fetch(signedRequest, {
                  method: "PUT",
                  headers: options.headers,
                  body: file
                });
                setFileUploading(false);
                attachmentUrl = bucketUrl;
              } catch (e) {
                attachmentUrl = null;
                console.log(e);
                setFileUploading(false);
              }
            } catch (e) {
              const errMsg = graphqlError(e, {
                // NOT_PERMITTED_FILE_TYPE: translations.accountSettingsErrorPasswordWrong,
                // NOT_PERMITTED_FILE_SIZE: translations.accountSettingsErrorPasswordWrong,
              });
              if (errMsg) {
                // toast.error(errMsg)
              } else {
                // toast.error('Cannot uplaod file(s)')
                console.log(e);
              }
              return [...(await arr)];
            }
            return [...(await arr), attachmentUrl];
          }
        }
      }, []);
      if (allFileDocuments && allFileDocuments.length > 0) {
        // dataObject.documents = allFileDocuments;
        dataObject.documents = allFileDocuments.map(file => ({
          fileUrl: file
        }));
      }
    }
    console.log(dataObject, "fffeef2");

    saveFileLabDoc({
      variables: {
        data: {
          ...(data && data._id && { _id: data._id }),
          ...dataObject
        }
      },
      update: (proxy, { data: { saveFileLabDocsAdmin = {} } }) => {
        if (!data || !data._id) {
          const mutationResult = saveFileLabDocsAdmin;
          ApolloCacheUpdater({
            proxy,
            queriesToUpdate: [getFileLabDocumentsData,countFileLabDocsQuery],
            searchVariables: {
              skip: 0
            },
            mutationResult: {
              ...mutationResult
            },
            ID: "_id"
          });
        }
      }
    })
      .then(() => {
        enqueueSnackbar("The Lab Document has been saved.", {
          variant: "success"
        });
        history.replace("/file-document");
      })
      .catch(e => {
        enqueueSnackbar("There was an error saving the Lab Document", {
          variant: "error"
        });
      });
    }catch(e){

    console.log(e,"ddsdsdd")

    }
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formFields}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({
          setFieldValue,
          values,
          handleChange,
          errors,
          touched,
          setFieldTouched
        }) => {
          return (
            <Form>
              {console.log(errors, "errors")}
              <Grid container spacing={6} justifyContent="space-between">
                <Grid
                  item
                  md={6}
                  lg={4}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <FormControl
                    fullWidth
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <InputLabel id="services-label">Service</InputLabel>
                    <Select
                      fullWidth
                      labelId="services-label"
                      name="services"
                      label="Service"
                      onChange={e => {
                        // For single select, we store a string rather than an array
                        setFieldValue("services", e.target.value);
                      }}
                      value={values?.services || ""}
                    >
                      {Object.entries(SERVICES).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value?.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="services">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          Title <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name={`title[${languageIndex}]`}
                      value={values?.title[languageIndex] || ""}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="title">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <Box style={{ marginBottom: "5%", marginTop: "5%" }}>
                    <label>
                      Upload image <span style={{ color: "red" }}>*</span>
                    </label>
                    <ImageUpload
                      name="image"
                      value={values?.image}
                      setFieldValue={setFieldValue}
                    />

                    <ErrorMessage name="image">
                      {msg => (
                        <FormHelperText
                          className="form-error"
                          style={{ color: "red" }}
                        >
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </Box>

                  <div style={{ border: "1px solid grey", padding: "10px" }}>
                    <label>
                      File{" "}
                      <span style={{ color: "red", marginRight: "10px" }}>
                        *
                      </span>
                    </label>
                    <InputFile
                      name="documents"
                      type="file"
                      setValue={setFieldValue}
                      addon={fileUploading && <Spinner />}
                      disabled={fileUploading}
                      value={values?.documents}
                      multiple={true}
                    />
                  </div>
                  <ErrorMessage name="documents">
                    {msg => (
                      <div className="form-error" style={{ color: "red" }}>
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>

                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          URI <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name="uri"
                      value={values?.uri}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="uri">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                </Grid>

                <Grid
                  item
                  md={6}
                  lg={7}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <FormControl fullWidth style={{ marginTop: "5px" }}>
                    <Wysiwyg
                      key={languageIndex}
                      name={`abstract[${languageIndex}]`}
                      label="Abstract"
                      placeholder={"Abstract"}
                      setValue={setFieldValue}
                      onChange={handleChange}
                      value={
                        (values?.abstract.length &&
                          values.abstract[languageIndex]) ||
                        ""
                      }
                      // setEditorValues={true}
                    />
                    <ErrorMessage name={"abstract"}>
                      {msg => (
                        <div
                          className="form-error"
                          style={{ color: "red", paddingTop: "50px" }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth style={{ marginTop: "50px" }}>
                    <Wysiwyg
                      key={languageIndex}
                      name={`citation[${languageIndex}]`}
                      label="Citation"
                      placeholder={"Citation"}
                      setValue={setFieldValue}
                      onChange={handleChange}
                      value={
                        (values?.citation.length &&
                          values.citation[languageIndex]) ||
                        ""
                      }
                      // setEditorValues={true}
                    />
                    <ErrorMessage name={"citation"}>
                      {msg => (
                        <div
                          className="form-error"
                          style={{ color: "red", paddingTop: "50px" }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={4}
                      style={{ marginTop: "70px" }}
                      maxRows={4}
                      placeholder="Add content"
                      name={`content[${languageIndex}]`}
                      value={values?.content[languageIndex] || ""}
                      onChange={handleChange}
                    />
                    <ErrorMessage name={"content"}>
                      {msg => (
                        <div
                          className="form-error"
                          style={{ color: "red", paddingTop: "50px" }}
                        >
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FieldArray
                    name="authors"
                    render={({ push, remove }) => (
                      <div className="add-author-div">
                        {values.authors.length === 0 && push("")}
                        {values.authors.map((review, index) => (
                          <div
                            key={index}
                            className="p-4 border rounded mb-4 space-y-2 add-author-main-div"
                          >
                            <FormControl fullWidth >
                              <TextField
                                fullWidth
                                label={<span>Name</span>}
                                type="text"
                                // value={review.name[languageIndex]}
                                // value={
                                //   values.authors[index] ||
                                //   ""
                                // }
                                value={values.authors[index] || ""} 
                                name={`authors.${index}`}
                                onChange={handleChange}
                              />
                              <ErrorMessage name={`authors.${index}`}>
                                {msg => (
                                  <FormHelperText style={{ color: "red" }}>
                                    {msg}
                                  </FormHelperText>
                                )}
                              </ErrorMessage>
                            </FormControl>
                       {index>0 &&(
                            <button
                              className="remove-button"
                              onClick={() => remove(index)}
                            >
                              <DeleteOutlineOutlined />
                            </button>
                            )}
                          </div>
                        ))}

                        <button
                          style={{ margintTop: "50px", cursor: "pointer" }}
                          type="button"
                          onClick={() => push("")}
                        >
                          Add Author
                        </button>
                      </div>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5} justifyContent="flex-end">
                <Grid container item sm={2} xs={3} justifyContent="flex-end">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid container item sm={2} xs={3} justifyContent="flex-end">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                  //  type="submit"
                    type={loading || s3Loading || uploadLoading ? 'button' : 'submit'}
                  >
                    {(loading || s3Loading || uploadLoading) ? <Spinner color="secondary" size={25} /> : "Save"}
                    
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default FileDocumentForm;

const InputFile = ({
  name,
  type,
  value,
  accept,
  setValue,
  multiple = false // Enable multiple file uploads by default
}) => {
  // Handle file change
  const handleFileChange = async e => {
    if (type === "file") {
      const files = Array.from(e.target.files); // Get selected files
      const fileDetails = await Promise.all(
        files.map(file => {
          return new Promise(resolve => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
              resolve({
                name: file.name,
                type: file.type.split("/")[0], // 'image', 'text', etc.
                data: fileReader.result, // Data URL of the file
                dataFile: file // Original file object
              });
            };
          });
        })
      );

      // If there are existing files in the value, append the new files
      const updatedFiles = value ? [...value, ...fileDetails] : fileDetails;
      setValue(name, updatedFiles); // Update the Formik state with the new list of files
    } else {
      setValue(name, e.target.value); // Handle non-file inputs
    }
  };

  // Handle file removal
  const handleRemoveFile = index => {
    const updatedFiles = value.filter((_, i) => i !== index); // Remove the file at the given index
    setValue(name, updatedFiles); // Update the Formik state with the remaining files
  };

  return (
    <>
      <div>
        <input
          className="form-input"
          name={name}
          type={type}
          onChange={handleFileChange}
          accept={accept}
          multiple={multiple} // Enable multiple file selection
        />
      </div>
      <div>
        {/* Display selected files below the input */}
        {value && value.length > 0 && (
          <div style={{ marginTop: "10px" }}>
            <strong>Selected Files:</strong>
            <ul>
              {value.map((file, index) => (
                <li
                  key={index}
                  style={{
                    wordWrap: "break-word",
                    wordBreak: "break-word"
                  }}
                >
                  {file.url || file.name}
                  <button
                    type="button"
                    onClick={() => handleRemoveFile(index)}
                    style={{ marginLeft: 10, color: "red" }}
                  >
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </>
  );
};
