import React, { useState } from "react";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  TextField
} from "@material-ui/core";
import Wysiwyg from "../Wysiwyg";
import ImageUpload from "../../components/ImageUpload";
import { DeleteOutlineOutlined } from "@material-ui/icons";
import { useMutation } from "@apollo/react-hooks";
import { _safeS3SignMutation } from "../../api";
import { saveNewsAdminData } from "../../api/_mutations";
import { useSnackbar } from "notistack";
import maxFileSize from '../../settings/max-file-size.json';
import ApolloCacheUpdater from 'apollo-cache-updater';
import history from "../../history";
import Spinner from "../../components/Spinner";

const NewsLetterForm = ({ data, languageIndex, languagesTotal }) => {
  const [safeS3Sign] = useMutation(_safeS3SignMutation);
  const [saveNewsAdmin, { loading }] = useMutation(saveNewsAdminData);
  const { maxSize } = maxFileSize;
  const { enqueueSnackbar } = useSnackbar();
  
  const initialValues = {
    find_more: data?.find_more || '',
    subject: Array.isArray(data?.subject) ? data?.subject : data?.subject ? [data.subject] : [],    news: data?.news?.length 
      ? data.news.map(item => ({
        details: Array.isArray(item.details) 
          ? item.details 
          : [item.details],
        image: item.image?.url || item.image || null
      }))
      : [{ 
          details: Array(languagesTotal).fill(''),
          image: null 
        }]
  };

  const validationSchema = Yup.object().shape({
    find_more: Yup.string()
      .trim()
      .nullable()
      .required('Link is required')
      .url('Please enter a valid URL (e.g., https://example.com)')
      .max(2000, 'URL cannot be longer than 2000 characters'),
      subject: Yup.array()
      .of(Yup.string()
        .trim()
        .min(3, 'Subject must be at least 3 characters long')
        .max(100, 'Subject cannot be longer than 100 characters')
      )
      .test("subject-ele", "Subject is required", function(value) {
        if (value && value[languageIndex]) return true;
        else return false;
      }),
    news: Yup.array().of(
      Yup.object().shape({
        details: Yup.array()
          .of(Yup.string())
          .test(
            'details-required',
            'Details are required',
            function(value) {
              return value && value[languageIndex] && value[languageIndex].trim() !== '';
            }
          ),
        image: Yup.mixed().required("News image is required")
      })
    ).min(1, "At least one news section is required")
  });

  const handleSubmit = async (values) => {
    const dataObject = {
      ...values
    };

    // Process news array uploads and transform to the requested format
    if (Array.isArray(dataObject.news)) {
      const transformedNews = [];

      await Promise.all(
        dataObject.news.map(async (item, index) => {
          let imageUrl = item.image || null;

          if (imageUrl && typeof imageUrl !== "string") {
            const { dataFile, name: filename } = imageUrl;
            const { size } = dataFile;

            if (size > maxSize) {
              imageUrl = null;
            } else {
              try {
                const response = await safeS3Sign({
                  variables: {
                    filename: filename,
                    filetype: imageUrl.type,
                  },
                });

                const { signedRequest, url: bucketUrl } = response.data._safeSignS3;
                const options = { headers: { "Content-Type": dataFile.type } };

                try {
                  await fetch(signedRequest, {
                    method: "PUT",
                    headers: options.headers,
                    body: dataFile,
                  });

                  imageUrl = bucketUrl;
                } catch (uploadError) {
                  console.error("Upload failed:", uploadError);
                  imageUrl = null;
                }
              } catch (signError) {
                console.error("Error signing request:", signError);
                imageUrl = null;
              }
            }
          }

          transformedNews[index] = {
            details: item.details,
            image: imageUrl
          };
        })
      );

      dataObject.news = transformedNews;
    }

    saveNewsAdmin({
      variables: {
        data: {
          ...(data && data._id) && { _id: data._id },
          ...dataObject
        }
      },
      update: (proxy, { data: { saveNewsAdmin = {} } }) => {
        if (!data || !data._id) {
          const mutationResult = saveNewsAdmin;
          ApolloCacheUpdater({
            proxy,
            queriesToUpdate: [],
            searchVariables: {
              skip: 0
            },
            mutationResult: {
              ...mutationResult,
            },
            ID: '_id',
          });
        }
      },
    })
    .then(() => {
      enqueueSnackbar("The news has been saved.", { variant: "success" });
      history.replace("/newsList");
    })
    .catch(e => {
      enqueueSnackbar("There was an error saving the news", { variant: "error" });
      console.error("Error saving news:", e);
    });
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, errors , handleChange }) => (
          <Form>
            <Grid container spacing={2} style={{ marginBottom: "20px" }}>
              <Grid item xs={8}>
                <FormControl fullWidth>
                  <TextField
                    name={`find_more`}
                    label="Find More Link"
                    placeholder="Add Find More Link (URL)"
                    variant="outlined"
                    fullWidth
                    value={values.find_more|| ''}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="find_more">
                    {msg => (
                      <FormHelperText style={{ color: "red", paddingTop: "10px" }}>
                        {msg}
                      </FormHelperText>
                    )}
                  </ErrorMessage>
                </FormControl>
              </Grid>
              <Grid item xs={4}>
                <FormControl fullWidth>
                  <TextField
                    name={`subject[${languageIndex}]`}
                    label="Subject"
                    placeholder="Subject for Mail"
                    variant="outlined"
                    fullWidth
                    value={values?.subject[languageIndex] || ''}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="subject">
                    {msg => (
                      <FormHelperText style={{ color: "red", paddingTop: "10px" }}>
                        {msg}
                      </FormHelperText>
                    )}
                  </ErrorMessage>
                </FormControl>
              </Grid>
            </Grid>

            <FieldArray
              name="news"
              render={(arrayHelpers) => (
                <div style={{ width: "100%" }}>
                  {values.news.map((newsItem, index) => (
                    <div
                      key={index}
                      style={{ 
                        padding: "15px", 
                        border: "1px solid #ddd", 
                        borderRadius: "5px", 
                        marginBottom: "15px",
                        position: "relative" 
                      }}
                    >
                      <FormControl fullWidth style={{ marginBottom: "15px" }}>
                        <Wysiwyg
                          key={`${index}-${languageIndex}`}
                          name={`news[${index}].details[${languageIndex}]`}
                          label="Details"
                          placeholder="Add News Details"
                          setValue={setFieldValue}
                          value={values.news[index].details[languageIndex] || ''}
                        />
                        <ErrorMessage name={`news[${index}].details`}>
                          {msg => (
                            <FormHelperText style={{ color: "red", paddingTop: "10px" }}>
                              {msg}
                            </FormHelperText>
                          )}
                        </ErrorMessage>
                      </FormControl>

                      <div style={{ marginTop: "10px" }}>
                        <label>
                          News Image <span style={{ color: "red" }}>*</span>
                        </label>
                        <ImageUpload
                          name={`news[${index}].image`}
                          value={values.news[index].image}
                          setFieldValue={setFieldValue}
                        />
                        <ErrorMessage name={`news[${index}].image`}>
                          {msg => (
                            <FormHelperText style={{ color: "red" }}>
                              {msg}
                            </FormHelperText>
                          )}
                        </ErrorMessage>
                      </div>

                      {values.news.length > 1 && (
                        <button
                          style={{ 
                            position: "absolute", 
                            top: "10px", 
                            right: "10px", 
                            background: "none", 
                            border: "none", 
                            cursor: "pointer" 
                          }}
                          type="button"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          <DeleteOutlineOutlined />
                        </button>
                      )}
                    </div>
                  ))}

                  <Button
                    variant="contained"
                    color="primary"
                    style={{ marginTop: "10px", marginBottom: "20px" }}
                    type="button"
                    onClick={() =>
                      arrayHelpers.push({
                        details: Array(languagesTotal).fill(''),
                        image: null
                      })
                    }
                  >
                    Add News Section
                  </Button>
                </div>
              )}
            />

            <Grid container spacing={2} justifyContent="flex-end">
              <Grid item>
                <Button variant="contained" onClick={() => history.goBack()}>
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" color="primary" type="submit" disabled={loading}>
                  {loading ? <Spinner color="secondary" size={25} /> : "Save"}
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default NewsLetterForm;