import React from 'react'
import Table from '../Table'
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from '@material-ui/core';
import ConfirmDelete from '../ConfirmDelete';


const CohortList = ({
    data,
    deleteCallback,
    sortFilter
  }) => {
  return (
    
    <Table
    data={
        
      data.map(item => ({
        name:
        item?.name && item?.name || "",

        countryName:
        item?.country &&  item?.country || "",
          
        name_eso:item?.name_eso &&  item?.name_eso || "",
        
        num_participants:item?.num_participants &&  item?.num_participants || "",

      actions: (
          <div
            style={{ display: "flex", alignItems: "baseline" }}
            className="isApprovedWrapper"
          >
         
            <ButtonGroup size="small" variant="text">
                <Button component={Link} to={`/cohort/${item?._id}`}>
                                Edit
                              </Button>
             

              <ConfirmDelete
                id={item._id}
                name={item.name}
                deleteCallback={deleteCallback}
              />
            </ButtonGroup>
          </div>
        ),
    }))



}
    labels={[
      {
        name: "Name of Cohort",
        field: "name"
      },
      
      {
        name: "Country",
        field: "countryName"
      },
      {
        name: "Name of ESO",
        field: "name_eso"
      },
      {
        name: "No. of participants",
        field: "num_participants"
      },
      {
        name: "Actions",
        field: "actions"
      }
    ]}

    sortFilter={sortFilter}
  />
  )
}

export default CohortList