import React from 'react';
import { Form, Formik } from 'formik';
import { Button, FormControl, Grid, TextField } from '@material-ui/core';

const ChatFilterForm = props => {
  const { handleClear, callback = () => {} } = props;
  
  return (
    <Formik
      initialValues={{ topic: "" }}
      onSubmit={values => { callback(values) }}
    >
      {({ values, handleChange, resetForm }) => {
        return (
          <Form name="filter_chat_list">
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="topic"
                  value={values.topic}
                  onChange={handleChange}
                  label="Search by Topic"
                />
              </Grid>
            </Grid>
            <div style={{ marginTop: 25 }}>
              <Grid container spacing={4} justifyContent="flex-start">
                <Grid item xs={3} sm={2}>
                  <Button
                    style={{ width: "100%" }}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    APPLY
                  </Button>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Button
                    style={{ width: "100%" }}
                    type="reset"
                    color="inherit"
                    variant="contained"
                    onClick={() => {
                      resetForm();
                      handleClear();
                    }}
                  >
                    Clear Filter
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default ChatFilterForm;
