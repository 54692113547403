import React from 'react'
import multivalues from '../../settings/multivalues.json'
import LanguageSwitch from '../../components/LanguageSwitch'
import BlogForm from '../../forms/BlogForm/BlogForm'
import Authenticated from '../../containers/Authenticated'
import Layout from '../../components/Layout'

const CohortNew = () => {
    const [languageIndex, setLanguageIndex] = React.useState(0)

    return (
      <Authenticated>
        <Layout title='Create Blog'>
          <LanguageSwitch
            languageIndex={languageIndex}
            languages={multivalues.languages}
            setLanguageIndex={setLanguageIndex}
          />
          <BlogForm
            languageIndex={languageIndex}
            languagesTotal={Object.keys(multivalues.languages).length}
          />
        </Layout>
      </Authenticated>
    )
}

export default CohortNew