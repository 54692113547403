import React, { useState, useEffect } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  CircularProgress
} from "@material-ui/core";
import { useSnackbar } from "notistack";
import { useMutation, useQuery } from "@apollo/react-hooks";
import history from "../../history";
import { SaveChatAdmin } from "../../api/_mutations";
import { GetChatTopics } from "../../api/_queries";
import Wysiwyg from "../Wysiwyg";

const ChatbotForm = ({ data, languageIndex, languagesTotal }) => {
  console.log(data, "datadata");
  const [changingLanguage, setChangingLanguage] = useState(false);

  // Transform the  incoming data so that initialValues are in the correct shape.
  // const transformData = (data) => {
  //   if (!data || !data.chat || data.chat.length === 0) return {};
  //   const firstChat = data.chat[0];
  //   const firstQuestion = (firstChat.questions && firstChat.questions.length > 0) ? firstChat.questions[0] : {};
  //   return {
  //     topic: firstChat.topic || "",
  //     question: firstQuestion.question || Array(languagesTotal).fill(""),
  //     answer: firstQuestion.answer || Array(languagesTotal).fill(""),
  //     topicOther: firstChat.topicOther || ""
  //   };
  // };

  const transformData = data => {
    if (!data || !data.chat || data.chat.length === 0)
      return {
        topic: "",
        question: Array(languagesTotal).fill(""),
        answer: Array(languagesTotal).fill(""),
        topicOther: ""
      };

    const firstChat = data?.chat[0] || {};
    const firstQuestion =
      firstChat.questions && firstChat.questions.length > 0
        ? firstChat.questions[0]
        : {
            question: Array(languagesTotal).fill(""),
            answer: Array(languagesTotal).fill("")
          };

    return {
      topic: firstChat.topic || "",
      question: firstQuestion.question || Array(languagesTotal).fill(""),
      answer: firstQuestion.answer || Array(languagesTotal).fill(""),
      topicOther: firstChat.topicOther || ""
    };
  };

  const [initialValues, setInitialValues] = useState(transformData(data));
  const { enqueueSnackbar } = useSnackbar();
  const firstUpdate = React.useRef(true);

  const {
    data: topicsData,
    loading: topicsLoading,
    error: topicsError,
    refetch
  } = useQuery(GetChatTopics, {
    fetchPolicy: "network-only" // Forces fresh data on every mount
  });
  const [saveChatAdmin, { loading }] = useMutation(SaveChatAdmin);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    if (data) {
      console.log(data, "data");
      setInitialValues(transformData(data));
    }
  }, [data, languagesTotal]);

  useEffect(() => {
    if (!firstUpdate.current) {
      setChangingLanguage(true);
      setInitialValues({ ...transformData(data) });
      setChangingLanguage(false);
    }
  }, [languageIndex, data, languagesTotal]);

  const handleSubmit = async fields => {
    console.log(fields, "fields");

    // Preserve existing values and update only the current language index
    const formattedQuestions = [
      ...(data?.chat?.[0]?.questions?.[0]?.question ||
        Array(languagesTotal).fill(""))
    ];
    formattedQuestions[languageIndex] = fields.question[languageIndex] || "";

    const formattedAnswers = [
      ...(data?.chat?.[0]?.questions?.[0]?.answer ||
        Array(languagesTotal).fill(""))
    ];
    formattedAnswers[languageIndex] = fields.answer[languageIndex] || "";

    const topicValue =
      fields.topic === "others" ? fields.topicOther : fields.topic;

    // Build the chatData object.
    const chatData = {
      _id: data?._id, // This will pass the id for editing if it exists.
      chat: [
        {
          topic: topicValue,
          questions: [
            {
              question: formattedQuestions,
              answer: formattedAnswers
            }
          ]
        }
      ]
    };

    try {
      await saveChatAdmin({ variables: { data: chatData } });
      enqueueSnackbar("The ChatAdmin entry has been saved.", {
        variant: "success"
      });
      history.replace("/chatList");
    } catch (e) {
      enqueueSnackbar("There was an error saving the ChatAdmin entry", {
        variant: "error"
      });
    }
  };

  const formFields = {
    question: initialValues?.question?.length
      ? initialValues.question
      : Array(languagesTotal).fill(""), // Ensure an array with empty strings
    answer: initialValues?.answer?.length
      ? initialValues.answer
      : Array(languagesTotal).fill(""), // Ensure an array with empty strings
    topic: initialValues?.topic || "",
    topicOther: initialValues?.topicOther || ""
  };

  const validationSchema = Yup.object().shape({
    topic: Yup.string().required("Topic is required."),
    topicOther: Yup.string().when("topic", {
      is: "others",
      then: Yup.string().required("Custom topic is required"),
      otherwise: Yup.string()
    }),
    question: Yup.array()
      .of(Yup.string())
      .test(
        "question-required",
        "Question is required for the selected language",
        function(value) {
          const currentValue = value?.[languageIndex];
          return currentValue && currentValue.trim().length > 0; // Ensures non-empty input
        }
      ),
    answer: Yup.array()
      .of(Yup.string().max(5000, "Answer must not exceed 1000 characters"))
      .test(
        "answer-required",
        "Answer is required for the selected language",
        function(value) {
          const currentValue = value?.[languageIndex];
          return currentValue && currentValue.trim().length > 0; // Ensures non-empty input
        }
      )
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formFields}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, handleChange }) => (
          <Form>
            <Grid container spacing={6} justifyContent="space-between">
              <Grid
                item
                md={6}
                lg={4}
                container
                direction="column"
                alignItems="flex-start"
              >
                {/* Topic Select Field */}
                <FormControl
                  fullWidth
                  style={{ marginTop: "20px", marginBottom: "20px" }}
                >
                  <InputLabel id="topic-label">Topic</InputLabel>
                  {topicsLoading ? (
                    <CircularProgress size={24} />
                  ) : topicsError ? (
                    <FormHelperText style={{ color: "red" }}>
                      Failed to load topics
                    </FormHelperText>
                  ) : (
                    <Select
                      fullWidth
                      labelId="topic-label"
                      name="topic"
                      label="Topic"
                      value={values.topic || ""}
                      onChange={handleChange}
                    >
                      {topicsData?.getTopics?.map(({ topic }) => (
                        <MenuItem key={topic} value={topic}>
                          {topic.toUpperCase()}
                        </MenuItem>
                      ))}
                      <MenuItem value="others">+ Add New Topic</MenuItem>
                    </Select>
                  )}
                  <ErrorMessage name="topic">
                    {msg => (
                      <FormHelperText style={{ color: "red" }}>
                        {msg}
                      </FormHelperText>
                    )}
                  </ErrorMessage>
                </FormControl>

                {values.topic === "others" && (
                  <FormControl fullWidth style={{ marginBottom: "20px" }}>
                    <TextField
                      label="Custom Topic *"
                      type="text"
                      name="topicOther"
                      value={values.topicOther}
                      onChange={handleChange}
                      variant="outlined"
                    />
                    <ErrorMessage name="topicOther">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                )}

                <FormControl fullWidth style={{ marginBottom: "15px" }}>
                  <label>Question  <span style={{ color: "red" }}>*</span></label>
                  <br />
                  {/* <TextareaAutosize
                      aria-label="minimum height"
                      minRows={4}
                      maxRows={4}
                      placeholder="Add Question"
                      name={`question[${languageIndex}]`}
                      value={values?.question[languageIndex] || ""}
                      onChange={handleChange}
                      style={{ width: "100%", marginTop: "20px", padding: "10px" }}

                    /> */}
                  <Wysiwyg
                    key={languageIndex}
                    name={`question[${languageIndex}]`}
                    label="add question"
                    placeholder={"Add question"}
                    setValue={setFieldValue}
                    value={values?.question[languageIndex] || ""}
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      padding: "10px"
                    }}
                    // value={values.information[index].details[languageIndex]||""}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="question">
                    {msg => (
                      <FormHelperText style={{ color: "red" }}>
                        {msg}
                      </FormHelperText>
                    )}
                  </ErrorMessage>
                </FormControl>
              </Grid>
              <Grid
                item
                md={6}
                lg={7}
                container
                direction="column"
                alignItems="flex-start"
              >
                <FormControl fullWidth>
                <label>Answer  <span style={{ color: "red" }}>*</span></label>
                <br />
                  {/* <TextareaAutosize
                    aria-label="minimum height"
                    minRows={6}
                    maxRows={10}
                    placeholder="Add Answer"
                    name={`answer[${languageIndex}]`}
                    value={values.answer[languageIndex] || ""}
                    onChange={handleChange}
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      padding: "10px"
                    }}
                  /> */}

                  <Wysiwyg
                    key={languageIndex}
                    label="add answer"
                    placeholder={"Add answer"}
                    setValue={setFieldValue}
                    name={`answer[${languageIndex}]`}
                    value={values.answer[languageIndex] || ""}
                    style={{
                      width: "100%",
                      marginTop: "20px",
                      padding: "10px"
                    }}
                    // value={values.information[index].details[languageIndex]||""}
                    onChange={handleChange}
                  />
                  <ErrorMessage name="answer">
                    {msg => (
                      <div className="form-error" style={{ color: "red" }}>
                        {msg}
                      </div>
                    )}
                  </ErrorMessage>
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={5}
              justifyContent="flex-end"
              style={{ marginTop: "20px" }}
            >
              <Grid container item sm={2} xs={3} justifyContent="flex-end">
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  onClick={() => history.goBack()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid container item sm={2} xs={3} justifyContent="flex-end">
                <Button
                  style={{ width: "100%" }}
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={loading}
                >
                  Save
                </Button>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ChatbotForm;
