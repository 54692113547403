import React from 'react'
import LanguageSwitch from '../../components/LanguageSwitch'
import Layout from '../../components/Layout'
import Authenticated from '../../containers/Authenticated'
import multivalues from '../../settings/multivalues.json'
import LabDocumentForm from '../../forms/LabDocumentForm/LabDocumentForm'
import ChatbotForm from '../../forms/ChatbotForm/ChatbotForm'


const ChatbotNew = () => {
    const [languageIndex, setLanguageIndex] = React.useState(0)
  return (
    <Authenticated>
        <Layout title='Create Chat-bot Q&A'>
          <LanguageSwitch
            languageIndex={languageIndex}
            languages={multivalues.languages}
            setLanguageIndex={setLanguageIndex}
          />
          <ChatbotForm
            languageIndex={languageIndex}
            languagesTotal={Object.keys(multivalues.languages).length}
          />
        </Layout>
      </Authenticated>
  )
}

export default ChatbotNew