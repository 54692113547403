import React from 'react'
import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@material-ui/core';
import { Form, Formik } from 'formik';
import SERVICES from "../../settings/enums/categories.json";


const FileDocumentFilterForm
 =props => {
    const { handleClear, callback = () => {} } = props;
  return (
    <Formik
      initialValues={{ title: "", services: "" }}
      
      onSubmit={values =>{ callback(values)}}
    >
      {({ values, handleChange, resetForm }) => {
        return (
          <Form name="filter_lab_document_list">
            <Grid container spacing={2} alignItems="flex-start">
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name="title"
                  value={values.title}
                  onChange={handleChange}
                  label="Search file document by title"
                />
              </Grid>
              <Grid item xs={4}>
              <FormControl fullWidth>
                  <InputLabel>Services</InputLabel>
                  <Select
                      
                      fullWidth
                      name="services"
                      label="Services offered"
                      onChange={handleChange}
                      value={values?.services }
                    
                    >
                      {Object.entries(SERVICES).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value?.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                </FormControl>
              </Grid>
              
            </Grid>
            <div style={{ marginTop: 25 }}>
              <Grid container spacing={4} justifyContent="flex-start">
                <Grid item xs={3} sm={2}>
                  <Button
                    style={{ width: "100%" }}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    APPLY
                  </Button>
                </Grid>
                <Grid item xs={4} sm={3}>
                  <Button
                    style={{ width: "100%" }}
                    type="reset"
                    color="inherit"
                    variant="contained"
                    onClick={() => {
                      resetForm();
                      handleClear();
                    }}
                  >
                    Clear Filter
                  </Button>
                </Grid>
              </Grid>
            </div>
          </Form>
        );
      }}
    </Formik>
  )
}

export default FileDocumentFilterForm
