import React from "react";
import Table from "../Table";
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from "@material-ui/core";
import ConfirmDelete from "../ConfirmDelete";

const ChatList = ({ data, deleteCallback, sortFilter }) => {
  console.log(data, "chat data");

  return (
    <Table
      data={data.flatMap((chatItem) =>
        chatItem.chat.flatMap((topicItem) =>
          topicItem.questions.map((qna, index) => ({
            topic: index === 0 ? topicItem.topic || "Untitled" : "",
            question: (
              <div dangerouslySetInnerHTML={{ __html: qna.question || "No Question" }} />
            ),
            answer: (
              <div dangerouslySetInnerHTML={{ __html: qna.answer || "No Answer" }} />
            ),
            actions: (
              <div style={{ display: "flex", alignItems: "baseline" }} className="isApprovedWrapper">
                {index === 0 && (
                  <ButtonGroup size="small" variant="text">
                    <Button component={Link} to={`/Chat/${chatItem._id}`}>Edit</Button>
                    <ConfirmDelete id={chatItem._id} name={topicItem.topic} deleteCallback={deleteCallback} />
                  </ButtonGroup>
                )}
              </div>
            ),
          }))
        )
      )}
      labels={[
        {
          name: "Topic",
          field: "topic",
        },
        {
          name: "Question",
          field: "question",
        },
        {
          name: "Answer",
          field: "answer",
        },
        {
          name: "Actions",
          field: "actions",
        },
      ]}
      sortFilter={sortFilter}
    />
  );
};

export default ChatList;
