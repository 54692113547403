import React, { useState } from "react";
import "./BlogForm.css";
import { ErrorMessage, FieldArray, Form, Formik } from "formik";
import ApolloCacheUpdater from 'apollo-cache-updater'
import * as Yup from "yup";
import {
  Box,
  Button,
  Chip,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField
} from "@material-ui/core";
import SERVICES from "../../settings/enums/categories.json";
import history from "../../history";

import Spinner from "../../components/Spinner";
import Wysiwyg from "../Wysiwyg";
import "./BlogForm.css"
import ImageUpload from "../../components/ImageUpload";
import { DeleteOutlineOutlined } from "@material-ui/icons";
import { _safeS3SignMutation } from "../../api";
import { useMutation } from "@apollo/react-hooks";
import { saveBlogAdminData } from "../../api/_mutations";
import { useSnackbar } from "notistack";
import maxFileSize from '../../settings/max-file-size.json'
import MultiImageUpload from "../../components/MultiImageUpload";


const BlogForm = ({ data, languageIndex, languagesTotal }) => {
     
  const [safeS3Sign] = useMutation(_safeS3SignMutation)
  const [saveBlogAdmin, { loading }] = useMutation(saveBlogAdminData);
  const { maxSize } = maxFileSize
  const [changingLanguage, setChangingLanguage] = useState();
  const [fileUploading, setFileUploading] = React.useState();
  const [initialValues, setInitialValues] = React.useState(data);
  const { enqueueSnackbar } = useSnackbar()
  const firstUpdate = React.useRef(true);

  console.log("data", data)
  React.useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    if (data) {
      setInitialValues(data);
    }
  }, [data, firstUpdate]);

  React.useEffect(() => {
    // Set initial values again after changing current language.
    if (!firstUpdate.current) {
      const handleAsync = async () => {
        await setChangingLanguage(true);
        await setInitialValues({ ...data, ...initialValues });
        await setChangingLanguage(false);
      };
      handleAsync();
    }
  }, [languageIndex]);


  const handleSubmit = async (fields) => {
    console.log("Fields before transformation:", fields);
  
    const dataObject = { ...fields };
  
    // ✅ Handle the 'information' array with image uploads and existing images
    if (Array.isArray(dataObject.information)) {
      const transformedInfo = await Promise.all(
        dataObject.information.map(async (item, index) => {
          const imageArray = Array.isArray(item.image) ? item.image : [];
  
          const uploadedImages = await Promise.all(
            imageArray.map(async (image) => {
              // ✅ Case 1: Existing image (URL present)
              if (typeof image.data === "string" && !image.dataFile) {
                return image.data;  // Use the existing image URL
              }
  
              // ✅ Case 2: New image upload (dataFile present)
              if (image.dataFile && image.dataFile.size <= maxSize) {
                try {
                  const response = await safeS3Sign({
                    variables: { filename: image.name, filetype: image.dataFile.type },
                  });
  
                  const { signedRequest, url: bucketUrl } = response.data._safeSignS3;
  
                  await fetch(signedRequest, {
                    method: "PUT",
                    headers: { "Content-Type": image.dataFile.type },
                    body: image.dataFile,
                  });
  
                  return bucketUrl;  // ✅ Return the uploaded image URL
                } catch (error) {
                  console.error("Image upload failed:", error);
                  return null;
                }
              }
  
              return null;  // If no valid condition is met
            })
          );
  
          return {
            details: item.details,
            fileUrl: uploadedImages.filter(Boolean),  // ✅ Remove null entries
          };
        })
      );
  
      dataObject.information = transformedInfo;  // ✅ Updated information array
    }
  
    // ✅ Handle main 'fileUrl' uploads (if any)
    if (Array.isArray(dataObject.fileUrl) && dataObject.fileUrl.some(file => typeof file !== 'string')) {
      const processedFiles = await Promise.all(
        dataObject.fileUrl.map(async (file) => {
          if (typeof file === 'string') return file;  // Existing URL
  
          const { dataFile, name: filename } = file;
          if (!dataFile || dataFile.size > maxSize) return null;
  
          try {
            const response = await safeS3Sign({
              variables: { filename, filetype: dataFile.type },
            });
  
            const { signedRequest, url: bucketUrl } = response.data._safeSignS3;
  
            await fetch(signedRequest, {
              method: "PUT",
              headers: { "Content-Type": dataFile.type },
              body: dataFile,
            });
  
            return bucketUrl;  // ✅ Uploaded file URL
          } catch (error) {
            console.error("Upload failed:", error);
            return null;
          }
        })
      );
  
      dataObject.fileUrl = processedFiles.filter(Boolean);  // ✅ Filter null values
    }
  
    // ✅ Handle 'blog_profile' image
    if (dataObject.blog_profile && typeof dataObject.blog_profile !== 'string') {
      const { dataFile, name: filename } = dataObject.blog_profile;
  
      if (dataFile && dataFile.size <= maxSize) {
        try {
          const response = await safeS3Sign({
            variables: { filename, filetype: dataObject.blog_profile.type },
          });
  
          const { signedRequest, url: bucketUrl } = response.data._safeSignS3;
  
          await fetch(signedRequest, {
            method: "PUT",
            headers: { "Content-Type": dataFile.type },
            body: dataFile,
          });
  
          dataObject.blog_profile = bucketUrl;  // ✅ Updated with uploaded URL
        } catch (error) {
          console.error("Error uploading blog_profile:", error);
          dataObject.blog_profile = null;
        }
      } else if (typeof dataObject.blog_profile.data === "string") {
        dataObject.blog_profile = dataObject.blog_profile.data;  // ✅ Use existing URL
      } else {
        dataObject.blog_profile = null;
      }
    }
  
    // ✅ Save the blog
    saveBlogAdmin({
      variables: {
        data: {
          ...(data && data._id) && { _id: data._id },
          ...dataObject,
        },
      },
      update: (proxy, { data: { saveBlogAdmin = {} } }) => {
        if (!data || !data._id) {
          ApolloCacheUpdater({
            proxy,
            queriesToUpdate: [],
            searchVariables: { skip: 0 },
            mutationResult: { ...saveBlogAdmin },
            ID: '_id',
          });
        }
      },
    })
      .then(() => {
        enqueueSnackbar("The Blog has been saved.", { variant: "success" });
        history.replace("/blogList");
      })
      .catch((e) => {
        enqueueSnackbar("There was an error saving the blog.", { variant: "error" });
        console.error("Error saving blog:", e);
      });
  };
  
  console.log(initialValues,"initialValues")

  
const formFields = {
  title: initialValues?.title || [],
  name: initialValues?.name || [],
  time: initialValues?.time || "",
  description: initialValues?.description || [],
  blog_profile: initialValues?.blog_profile?.url || null,
  information: initialValues?.information?.length
  ? initialValues.information.map(({ __typename, fileUrl, ...rest }) => {
      // 📝 Handle details array
      let detailsArray;

      if (typeof rest.details === 'string') {
        detailsArray = Array(languagesTotal).fill('');
        detailsArray[0] = rest.details;
      } else if (Array.isArray(rest.details)) {
        detailsArray = [...rest.details];
        while (detailsArray.length < languagesTotal) {
          detailsArray.push('');
        }
      } else {
        detailsArray = Array(languagesTotal).fill('');
      }

      // 🖼️ Transform fileUrl into image array expected by MultiImageUpload
      const imageArray = Array.isArray(fileUrl)
        ? fileUrl.map((file, index) => ({
            data: file.url || '',                // ✅ URL for displaying the image
            name: `image-${index + 1}`,          // ✅ Unique name for the image
            id: file._id || null,                // ✅ Keep the original _id for reference
          }))
        : [];

      return {
        ...rest,
        details: detailsArray,
        image: imageArray,  // ✅ Add transformed images
      };
    })
  : [
      {
        details: Array(languagesTotal).fill(''),
        image: [], // ✅ Set to empty array if no information exists
      }
    ],

  tags: initialValues?.tags || "",
};

  let validationSchema = Yup.object().shape({
    title: Yup.array().of(Yup.string()).test(
      'title-element',
      'This field is required',
      function (value) {
        if (value && value[languageIndex]) return true
        else return false
      }),
    name: Yup.array().of(Yup.string()).test(
      'name-element',
      'This field is required',
      function (value) {
        if (value && value[languageIndex]) return true
        else return false
      }),
    time: Yup.string()
      .required("Time is required"),
    blog_profile: Yup.mixed()
      .required("Blog image is required"),
    description: Yup.array().of(Yup.string()).test(
      'description-element',
      'This field is required',
      function (value) {
        if (value && value[languageIndex]) return true
        else return false
      }),
      information:Yup.array().of(
        Yup.object().shape({
          details: Yup.mixed()
  .test("detail-element", "This field is required", function (value) {
    if (Array.isArray(value)) {
      return value[languageIndex] ? true : false;
    }
    return Boolean(value); // Accepts a single string
  }),

  image: Yup.array()
  .min(1, 'At least one image is required') // ✅ Ensures at least one image is uploaded
  .of(Yup.mixed().required('Image file is required')),
  
          // image: Yup.mixed().required('Image file is required'),
        })
      )

});

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formFields}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, handleChange, errors }) => {
          return (
            <Form>
              {console.log(errors, "errors")}
              <Grid container spacing={6} justifyContent="space-between">
                <Grid
                  item
                  md={6}
                  lg={4}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          Title <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name={`title[${languageIndex}]`}
                      value={values?.title[languageIndex] || ""}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="title">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          Author Name <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name={`name[${languageIndex}]`}
                      value={values?.name[languageIndex] || ""}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="name">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <InputLabel id="services-label">Service</InputLabel>
                    <Select
                      fullWidth
                      labelId="services-label"
                      name="f"
                      label="Service"
                      onChange={(e) => {
                        // For single select, we store a string rather than an array
                        setFieldValue("tags", e.target.value);
                      }}
                      value={values?.tags || ""}
                    >
                      {Object.entries(SERVICES).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value?.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="services">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth style={{ marginBottom: "20px" }}>
                    <TextField
                      label={
                        <span>
                          Time <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name="time"
                      value={values?.time || ""}
                      onChange={handleChange}
                      placeholder="Enter time (e.g., 10 minutes or 1 hours and 10 minutes)"
                    />
                    <ErrorMessage name="time">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth style={{ marginBottom: "20px" }}>
                    <div>
                      <label>
                        Blog Image <span style={{ color: "red" }}>*</span>
                      </label>
                      <ImageUpload
                        name="blog_profile"
                        value={values.blog_profile}
                        setFieldValue={setFieldValue}
                      />
                      <ErrorMessage name="blog_profile">
                        {msg => (
                          <FormHelperText style={{ color: "red" }}>
                            {msg}
                          </FormHelperText>
                        )}
                      </ErrorMessage>
                    </div>
                  </FormControl>
               
                </Grid>

                <Grid
                  item
                  md={6}
                  lg={7}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                 <FormControl fullWidth>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={4}
                      maxRows={4}
                      placeholder="Add Description"
                      name={`description[${languageIndex}]`}
                      value={values?.description[languageIndex] || ""}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="description">
                      {msg => (
                        <div className="form-error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FieldArray
                    name="information"
                    render={(arrayHelpers) => (
                      <div className="add-information-div" style={{ width: "100%", marginTop: "20px" }}>
                        
                        {values.information.map((info, index) => (
                          <div
                            key={index}
                            className="p-4 border rounded mb-4 space-y-2"
                            style={{ 
                              padding: "15px", 
                              border: "1px solid #ddd", 
                              borderRadius: "5px", 
                              marginBottom: "15px",
                              position: "relative" 
                            }}
                          >
                           <FormControl fullWidth style={{ marginBottom: "15px" }}>
                              <Wysiwyg
                                key={languageIndex}
                                name={`information[${index}].details[${languageIndex}]`}
                                label="Details"
                                placeholder={"Add Details"}
                                setValue={setFieldValue}
                                value={values?.information[index]?.details[languageIndex] || ''}
                                // value={values.information[index].details[languageIndex]||""}
                                onChange={handleChange}
                              />
                        
                              <ErrorMessage name={`information[${index}].details`}>
                              {msg => (
                                  <FormHelperText style={{ color: "red", paddingTop: "10px" }}>
                                    {msg}
                                  </FormHelperText>
                                )}
                              </ErrorMessage>
                            </FormControl>

                            <div style={{ marginTop: "10px" }}>
                              <label>
                                Section Image <span style={{ color: "red" }}>*</span>
                              </label>
                              
                              <MultiImageUpload
                                name={`information[${index}].image`}
                                value={values.information[index].image}
                                setValue={setFieldValue}
                              />
                              <ErrorMessage name={`information[${index}].image`}>
                                {msg => (
                                  <FormHelperText style={{ color: "red" }}>
                                    {msg}
                                  </FormHelperText>
                                )}
                              </ErrorMessage>
                            </div>
                        <div className="delete-button-div">
                        {index>0 &&(
                            <button
                              style={{ 
                                position: "absolute", 
                                top: "10px", 
                                right: "10px", 
                                background: "none", 
                                border: "none", 
                                cursor: "pointer" 
                              }}
                              type="button"
                              onClick={() => arrayHelpers.remove(index)}
                            >
                              <DeleteOutlineOutlined />
                            </button>
                        )}
                           </div>
                          </div>
                        ))}

                        <button
                          style={{ marginTop: "10px", marginBottom: "20px", cursor: "pointer" }}
                          type="button"
                          onClick={() =>
                            arrayHelpers.push({
                              details: "",
                              image: [],
                            })
                          }
                        >
                          Add Information Section
                        </button>
                        
                        {values.information.length === 0 && (
                          <div style={{ color: "red", marginTop: "5px" }}>
                            At least one information section is required
                          </div>
                        )}
                      </div>
                    )}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={5} justifyContent="flex-end">
                <Grid container item sm={2} xs={3} justifyContent="flex-end">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid container item sm={2} xs={3} justifyContent="flex-end">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {loading ? <Spinner color='secondary' size={25} /> : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default BlogForm;