import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Dashboard,
  Business,
  Comment,
  Person,
  Description,
  TableChart,
  Poll,
  ViewCarousel,
  Help,
  Language,
  DonutLarge,
  Timeline,
BusinessCenter,
PeopleRounded,
LibraryAddCheckRounded,
ReceiptRounded,
InsertDriveFileRounded,
DescriptionRounded,
LiveHelp
} from '@material-ui/icons'
import UserPanelContainer from '../containers/UserPanelContainer'
import SideMenu from './SideMenu'

const Sidebar = () => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <UserPanelContainer />
      <SideMenu links={[
        {
          label: 'Organisations',
          url: '/organizations',
          icon: <Business />,
        },
        {
          label: 'Newsfeed',
          url: '/newsfeed',
          icon: <Comment />,
        },
        {
          label: 'Resources',
          url: '/resources',
          icon: <Description />,
        },
        {
          label: 'Users',
          url: '/users',
          icon: <Person />,
        },
        {
          label: 'Survey',
          url: '/survey',
          icon: <Poll />,
        },
        {
          label: 'Carousel',
          url: '/carousel',
          icon: <ViewCarousel />,
        },
        {
          label: 'M&E',
          url: '/m-e',
          icon: <Dashboard />,
        },
        {
          label: 'Analytics',
          url: '/analytics',
          icon: <Timeline />,
        },
        {
          label: 'FAQ',
          url: '/FAQ',
          icon: <Help />,
        },
        {
          label: 'Dynamic Survey',
          url: '/dynamicSurvey',
          icon: <DonutLarge />,
        },
        {
          label: 'Job Portal',
          url: '/jobList',
          icon: <BusinessCenter />,
        },
        {
          label: 'Languages',
          url: '/language',
          icon: <Language />,
        },
        {
          label: 'AVV',
          url: '/avv',
          icon: <TableChart />,
        },
        {
          label: 'Cohort',
          url: '/cohortList',
          icon: <PeopleRounded />,
        },
        {
          label: 'Blogs',
          url: '/blogList',
          icon: <LibraryAddCheckRounded />,
        },
        {
          label: 'News Letter',
          url: '/newsList',
          icon: <ReceiptRounded />,
        },
        {
          label: 'Video Document',
          url: '/lab-document',
          icon: <InsertDriveFileRounded />,
        },
         {
            label: 'Lab Document',
            url: '/file-document',
            icon: <DescriptionRounded />,
          },
        {
          label: 'Chat Q&A',
          url: '/chatList',
          icon: <LiveHelp />,
        }
      ]} />
    </div>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.grey[700],
    height: '100%',
    boxShadow: theme.shadows[3],
  },
}))

export default Sidebar
