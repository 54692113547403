import React, { useState, useEffect } from "react";
import { ErrorMessage, Form, Formik } from "formik";
import * as Yup from "yup";
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField
} from "@material-ui/core";
import SERVICES from "../../settings/enums/categories.json";
import history from "../../history";
import { useMutation } from "@apollo/react-hooks";
import Spinner from "../../components/Spinner";
import { useSnackbar } from "notistack";
import ApolloCacheUpdater from "apollo-cache-updater";
import { saveLabDocumentsData } from "../../api/_mutations";
import { countLabDocsQuery, getLabDocumentsData } from "../../api/_queries";

const LabDocumentForm = ({ data, languageIndex, languagesTotal }) => {
  const [changingLanguage, setChangingLanguage] = useState();

  const [initialValues, setInitialValues] = React.useState(data);

  const { enqueueSnackbar } = useSnackbar();
  const firstUpdate = React.useRef(true);

  const [saveLabDocumentAdmin, { loading }] = useMutation(saveLabDocumentsData);

  useEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
    }
    if (data) {
      setInitialValues(data);
    }
  }, [data, firstUpdate]);

  useEffect(() => {
    // Set initial values again after changing current language.
    if (!firstUpdate.current) {
      const handleAsync = async () => {
        await setChangingLanguage(true);

        await setInitialValues({ ...data, ...initialValues });
        await setChangingLanguage(false);
      };
      handleAsync();
    }
  }, [languageIndex]);

  const handleSubmit = async fields => {
    const dataObject = {
      ...fields
    };

    saveLabDocumentAdmin({
      variables: {
        data: {
          ...(data && data._id && { _id: data._id }),
          ...dataObject
        }
      },
      update: (proxy, { data: { saveLabDocumentAdmin = {} } }) => {
        if (!data || !data._id) {
          const mutationResult = saveLabDocumentAdmin;
          ApolloCacheUpdater({
            proxy,
            queriesToUpdate: [getLabDocumentsData, countLabDocsQuery],
            searchVariables: {
              skip: 0
            },
            mutationResult: {
              ...mutationResult,
              _id: data?._id
            },
            ID: "_id"
          });
        }
      }
    })
      .then(() => {
        enqueueSnackbar("The Lab Document has been saved.", {
          variant: "success"
        });

        history.replace("/lab-document");
      })
      .catch(e => {
        enqueueSnackbar("There was an error saving the lab document", {
          variant: "error"
        });
      });
  };

  const formFields = {
    title: initialValues?.title || [],
    description: initialValues?.description || [],
    link: initialValues?.link || "",
    services: initialValues?.services || []
  };

  const videoUrlRegex = /^(https?:\/\/.*\.(mp4|mov|avi|mkv|flv|wmv|webm|ogg)(\?.*)?$|(?:https?:\/\/)?(?:www\.)?(youtube\.com\/watch\?v=|youtu\.be\/|vimeo\.com\/|dailymotion\.com\/video\/|facebook\.com\/.*\/videos\/|twitch\.tv\/videos\/|twitter\.com\/.*\/status\/)([a-zA-Z0-9_-]+))$/;


  


  let validationSchema = Yup.object().shape({
    title: Yup.array()
      .of(Yup.string())
      .test("title-element", "This field is required", function(value) {
        if (value && value[languageIndex]) return true;
        else return false;
      }),

    description: Yup.array()
      .of(
        Yup.string().max(
          300,
          "Description field must not exceed 300 characters"
        )
      )
      .test("description-element", "This field is required", function(value) {
        if (value && value[languageIndex]) return true;
        else return false;
      }).nullable(),

    link: Yup.string()
      .matches(/^(https?:\/\/)/, "URL should start with http:// or https://")
      .matches(videoUrlRegex, "This is not a valid video URL.")
      .required("This field is required."),


   

    services: Yup.array()
      .min(1, "This field is required.")
      .nullable()
  });

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={formFields}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, handleChange, errors }) => {
          return (
            <Form>
              <Grid container spacing={6} justifyContent="space-between">
                <Grid
                  item
                  md={6}
                  lg={4}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <FormControl
                    fullWidth
                    style={{ marginTop: "20px", marginBottom: "20px" }}
                  >
                    <InputLabel id="services-label">Service</InputLabel>

                    <Select
                      fullWidth
                      multiple={false}
                      labelId="services-label"
                      name="services"
                      label="Service"
                      onChange={handleChange}
                      value={values?.services || []}
                      //   onChange={(e) => {
                      //     const selectedValue = Array.isArray(e.target.value) ? e.target.value[0] : e.target.value;
                      //     setFieldValue("services", selectedValue);
                      //   }}
                    >
                      {Object.entries(SERVICES).map(([key, value]) => (
                        <MenuItem key={key} value={key}>
                          {value?.label?.toUpperCase()}
                        </MenuItem>
                      ))}
                    </Select>
                    <ErrorMessage name="services">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          Title <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name={`title[${languageIndex}]`}
                      value={values?.title[languageIndex] || ""}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="title">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  md={6}
                  lg={7}
                  container
                  direction="column"
                  alignItems="flex-start"
                >
                  <FormControl fullWidth>
                    <TextareaAutosize
                      aria-label="minimum height"
                      minRows={4}
                      maxRows={4}
                      placeholder="Add Description"
                      name={`description[${languageIndex}]`}
                      value={values?.description[languageIndex] || ""}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="description">
                      {msg => (
                        <div className="form-error" style={{ color: "red" }}>
                          {msg}
                        </div>
                      )}
                    </ErrorMessage>
                  </FormControl>

                  <FormControl fullWidth>
                    <TextField
                      label={
                        <span>
                          Video URL <span style={{ color: "red" }}>*</span>
                        </span>
                      }
                      type="text"
                      name={`link`}
                      value={values?.link}
                      onChange={handleChange}
                    />
                    <ErrorMessage name="link">
                      {msg => (
                        <FormHelperText style={{ color: "red" }}>
                          {msg}
                        </FormHelperText>
                      )}
                    </ErrorMessage>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container spacing={5} justifyContent="flex-end">
                <Grid container item sm={2} xs={3} justifyContent="flex-end">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    onClick={() => history.goBack()}
                  >
                    Cancel
                  </Button>
                </Grid>
                <Grid container item sm={2} xs={3} justifyContent="flex-end">
                  <Button
                    style={{ width: "100%" }}
                    variant="contained"
                    color="primary"
                    type="submit"
                  >
                    {loading ? <Spinner color="secondary" size={25} /> : "Save"}
                  </Button>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default LabDocumentForm;
