import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Loader from "../../components/Loader";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import CohortFilterForm from "../../forms/CohortFilterForm/CohortFilterForm";
import history from "../../history";
import { countBlogQuery, getNewsLetterData } from "../../api/_queries";
import { deleteBlogMutation, deleteCohortMutation, deleteNewsLetterMutation } from "../../api/_mutations";
import ApolloCacheUpdater from "apollo-cache-updater";
import { useSnackbar } from "notistack";
import Pagination from "../../components/Pagination";
import NewsLetterFilterForm from "../../forms/NewsLetterFilterForm/NewsLetterFilterForm";
import NewsLetterList from "../../components/NewsLetterList/NewsLetterList";

const NewsLetterPage = () => {
  const { enqueueSnackbar } = useSnackbar()

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sortData, setSortData] = useState(["_id:desc"]);

  const [deleteNewsLetter] = useMutation(deleteNewsLetterMutation, {})

  const parseFilters = useMemo(() => {
    const { subject, details, findMore } = filters;

    const escapeString = (str) => {
      if (!str) return "";
      return str
        .replace(/\\/g, "\\\\\\\\")
        .replace(/\!/g, "\\\\!")
        .replace(/\@/g, "\\\\@")
        .replace(/\#/g, "\\\\#")
        .replace(/\$/g, "\\\\\\$")
        .replace(/\%/g, "\\\\%")
        .replace(/\^/g, "\\\\^")
        .replace(/\&/g, "\\\\&")
        .replace(/\*/g, "\\\\*")
        .replace(/\)/g, "\\\\)")
        .replace(/\(/g, "\\\\(")
        .replace(/\[/g, "\\\\[")
        .replace(/\]/g, "\\\\]")
        .replace(/\;/g, "\\\\;")
        .replace(/\{/g, "\\\\{")
        .replace(/\}/g, "\\\\}")
        .replace(/\?/g, "\\\\?")
        .replace(/\,/g, "\\\\,");
    };

    const searchKeywordSubject = subject ? escapeString(subject) : "";
    const searchKeywordDetails = details ? escapeString(details) : "";
    const searchKeywordFindMore = findMore ? escapeString(findMore) : "";

    return {
      ...(subject && { subject_contains: searchKeywordSubject }),
      ...(details && { "news.details_contains": searchKeywordDetails }),
      ...(findMore && { find_more_contains: searchKeywordFindMore })
    };
  }, [filters]);

  const {
    data: { getNewss: newslettersList = [], countNewsLetters: rowsInTotal } = {},
    loading,
    error,
    refetch
  } = useQuery(getNewsLetterData, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters &&
          Object.keys(filters).length &&
          Object.keys(parseFilters).length
          ? { where: parseFilters }
          : {})
      }
    },
    fetchPolicy: 'no-cache'
  });

  const handleDeleteCallback = useCallback(async (id, subject) => {
    deleteNewsLetter({
      variables: {
        id,
      },
      update: (proxy) => {
        const update = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getNewsLetterData],
          searchVariables: {
            params: {
              sort: sortData,
              limit: rowsPerPage,
              skip: page * rowsPerPage,
              ...(filters && Object.keys(filters).length && Object.keys(parseFilters).length
                ? { where: parseFilters }
                : {}
              ),
            }
          },
          operator: "ANY",
          operation: "REMOVE",
          mutationResult: { _id: id },
          ID: "_id",
        });
        
        if (update) {
          enqueueSnackbar(`Newsletter ${subject} has been deleted.`, { variant: "success" });
        }
      },
    })
    refetch()
      .then(() => {
        // Additional success handling if needed
      })
      .catch((e) => {
        console.error("Delete newsletter error:", e);
        enqueueSnackbar("Cannot delete Newsletter.", { variant: "error" });
      });
  }, [page, rowsPerPage, filters, parseFilters]);

  const sortFilter = useCallback((value, key) => {
    setSortData([`${value}:${key}`]);
  }, []);

  const handleClear = useCallback(() => {
    setFilters({});
    history.push("/newsList");
  }, [history]);

  const handleFiltersSubmit = useCallback(fields => {
    setPage(0);
    setFilters({ ...fields });
  }, []);

  const handleRowPerChange = (value) => {
    setRowsPerPage(value)
  }

  return (
    <Authenticated>
      <Layout
        title="News-Letter List"
        head={
          <NewsLetterFilterForm
            handleClear={handleClear}
            callback={handleFiltersSubmit}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <NewsLetterList
            data={newslettersList}
            sortFilter={sortFilter}
            deleteCallback={handleDeleteCallback}
          />

          {rowsInTotal > rowsPerPage && (
            <Pagination
              page={page}
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              callback={newPage => setPage(newPage)}
              handleRowPerChange={handleRowPerChange}
            />
          )}
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default NewsLetterPage;