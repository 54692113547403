import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Wysiwyg = ({ 
  name,
  value,
  setValue,
  placeholder,
  
}) => {
  const handleChange = (newValue) => {
    // Normalize empty values (ReactQuill returns "<p><br></p>" when empty)
    const normalizedValue = newValue === "<p><br></p>" ? "" : newValue;

    // Update Formik state (Formik will handle validation automatically)
    setValue(name, normalizedValue);
  };

  return (
    <ReactQuill
      value={value}
      // onChange={(newValue) => {
       
      //   setValue(name, newValue); // Updating Formik state
      // }}
      onChange={handleChange}
      modules={{
        toolbar: [
          [{ header: [3, false] }],
          ["bold", "italic"],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link"]
        ]
      }}
      placeholder={placeholder}
    />
  );
};
Wysiwyg.propTypes = {
  value: PropTypes.any,
  placeholder: PropTypes.string,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired
};

Wysiwyg.displayName = "Wysiwyg";

export default Wysiwyg;
