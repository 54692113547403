import React, { useEffect, useState } from "react";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import { useLazyQuery } from "@apollo/react-hooks";
import { getJobByIdQuery } from "../../api";
import Loader from "../../components/Loader";
import { Button, ButtonGroup, FormControl, Select } from "@material-ui/core";
import { useMutation } from "@apollo/react-hooks";
import { saveJobQueriesData } from "../../api";
import { useSnackbar } from "notistack";
import "./JobPortal.css";
import {useHistory} from "react-router-dom"

const JobView = ({
  match: {
    params: { id }
  }
}) => {
  
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const [
    runJOBQuery,
    { data: { getJobById: data } = {}, loading, error }
  ] = useLazyQuery(getJobByIdQuery);

  useEffect(() => {
    runJOBQuery({
      variables: {
        id: id
      }
    });
  }, [id]);

  const [saveJob] = useMutation(saveJobQueriesData);
  const [approvedValue, setApprovedValue] = useState({
    id: null,
    jobStatus: null
  });

  const updateApprovedStatus = () => {
    if (approvedValue.id) {
      saveJob({
        variables: {
          data: approvedValue
        }
      })
        .then(res => {
          console.log(res);
          setApprovedValue({
            id: null,
            jobStatus: null
          });
        })
        .catch(err => {
          console.log(err);
        });
    } else {
      enqueueSnackbar("Please change the status of job.", { variant: "error" });
    }
  };

  return (
    <Authenticated>
      <Layout title="View Job">
        <Loader loading={loading} error={error}>
          <div className="view-job-info-main-wrapper">
            <div className="view-job-info-wrapper">
              {data?.image && (
                <div className="view-job-info-logo">
                  <img src={data?.image} />
                </div>
              )}
              <ul className="view-job-info-list">
                <li>Title : {data?.jobTitle}</li>
                <li>company Name : {data?.companyName}</li>
                <li>
                  Location : {data?.city},{data?.country}
                </li>
                <li>Tags: {data?.tags[0]}</li>
              </ul>
            </div>
            <div className="view-job-info-details-title">
              <h4>Job Details</h4>
              <ul>
                <li>Job Role : {data?.department}</li>
                <li>Job Level: {data?.jobLevel}</li>
                <li>Job Type: {data?.jobType}</li>
                <li>
                  Salry: {data?.maxSalary} - {data?.minSalary}
                </li>
                <li>Salary Type: {data?.salaryType}</li>
                <li>No. of Vacancies: {data?.vacancies}</li>
                <li>Work Mode: {data?.workMode}</li>
                <li>Visit Link: {data?.link}</li>
                Job Expired On:{" "}
                {(() => {
                  const expirationDate = new Date(data?.expirationDate); // Parse the date
                  const options = {
                    day: "2-digit",
                    month: "short",
                    year: "numeric"
                  };
                  const formattedDate = expirationDate.toLocaleDateString(
                    "en-US",
                    options
                  );
                  const hours = expirationDate
                    .getUTCHours()
                    .toString()
                    .padStart(2, "0"); // UTC hours
                  const minutes = expirationDate
                    .getUTCMinutes()
                    .toString()
                    .padStart(2, "0"); // UTC minutes
                  return `${formattedDate} at ${hours}:${minutes} GMT`;
                })()}
                <li>Job Desciption: </li>
                <span
                  dangerouslySetInnerHTML={{ __html: data?.jobDescription }}
                />
              </ul>
            </div>
            <div className="view-job-info-requirements-title">
              <h4>Requirenments For Job</h4>
              <ul>
                <li>Education : {data?.education}</li>
                <li>Experience: {data?.experiance}</li>
              </ul>
            </div>
            <div className="view-job-info-details-status-changes-main-div">
              <FormControl
                variant="outlined"
                className={
                  data?.jobStatus === "approved" ? "approved" : "pending"
                }
              >
                <Select
                  native
                  defaultValue={data?.jobStatus ? data.jobStatus : "pending"}
                  onChange={e => {
                    setApprovedValue({
                      id: data?._id,
                      jobStatus: e.target.value
                    });
                  }}
                >
                  <option value="pending" key="Pending">
                    Pending
                  </option>
                  <option value="approved" key="Approved">
                    Approved
                  </option>
                </Select>
              </FormControl>
              <Button
                className="view-job-info-details-update-button"
                onClick={() => updateApprovedStatus()}
              >
                Update
              </Button>
              <Button
                className="view-job-info-details-update-button"
                onClick={() => history.push("/jobList")}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default JobView;
