import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Loader from "../../components/Loader";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import "./FileDocumentPage.css";
import {
  deleteFileLabDocumentMutation
} from "../../api/_mutations";
import ApolloCacheUpdater from "apollo-cache-updater";
import { useSnackbar } from "notistack";
import Pagination from "../../components/Pagination";
import history from "../../history";
import FileDocumentFilterForm from "../../forms/FileDocumentFilterForm/FileDocumentFilterForm";
import FileDocumentList from "../../components/FileDocumentList/FileDocumentList";
import {
  countFileLabDocsQuery,
  getFileLabDocumentsData
} from "../../api/_queries";

const FileDocumentPage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sortData, setSortData] = useState(["_id:desc"]);

  const parseFilters = useMemo(() => {
    const { title, services } = filters;
    const escapeString = str => {
      if (!str) return "";
      return str
        .replace(/\\/g, "\\\\\\\\")
        .replace(/\!/g, "\\\\!")
        .replace(/\@/g, "\\\\@")
        .replace(/\#/g, "\\\\#")
        .replace(/\$/g, "\\\\\\$")
        .replace(/\%/g, "\\\\%")
        .replace(/\^/g, "\\\\^")
        .replace(/\&/g, "\\\\&")
        .replace(/\*/g, "\\\\*")
        .replace(/\)/g, "\\\\)")
        .replace(/\(/g, "\\\\(")
        .replace(/\[/g, "\\\\[")
        .replace(/\]/g, "\\\\]")
        .replace(/\;/g, "\\\\;")
        .replace(/\{/g, "\\\\{")
        .replace(/\}/g, "\\\\}")
        .replace(/\?/g, "\\\\?")
        .replace(/\,/g, "\\\\,");
    };

    const searchKeywordTitle = title ? escapeString(title) : "";
    const searchKeywordTags = services ? escapeString(services) : "";

    return {
      ...(title && { title_contains: searchKeywordTitle }),
      ...(services && { services_contains: searchKeywordTags })
    };
  }, [filters]);

  const [deleteFileLabDocument] = useMutation(
    deleteFileLabDocumentMutation,
    {}
  );

  const {
    data: {
      getFileLabDocs: fileLabDocumentList = [],
      countFileLabDocs: rowsInTotal
    } = {},
    loading,
    error
  } = useQuery(getFileLabDocumentsData, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters &&
        Object.keys(filters).length &&
        Object.keys(parseFilters).length
          ? { where: parseFilters }
          : {})
      },
      fetchPolicy: "no-cache"
    }
  });

  const handleDeleteCalllback = useCallback(async (id, name) => {
    deleteFileLabDocument({
      variables: {
        id
      },
      refetchQueries: [
        {
          query: getFileLabDocumentsData,
          variables: {
            params: {
              limit: rowsPerPage,
              skip: page * rowsPerPage,
              ...(filters && Object.keys(filters).length
                ? { where: parseFilters }
                : {})
            }
          }
        }
      ],
      update: proxy => {
        const update = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getFileLabDocumentsData, countFileLabDocsQuery], // queries you want to automatically update
          searchVariables: {},
          operator: "ANY",
          operation: "REMOVE",
          mutationResult: { _id: id },
          ID: "_id"
        });
        if (update)
          enqueueSnackbar(`Lab Document ${name} has been deleted.`, {
            variant: "success"
          });
      }
    })
      .then(() => {})
      .catch(e => {
        enqueueSnackbar("Cannot delete Lab Document.", { variant: "error" });
      });
  }, []);

  const sortFilter = useCallback((value, key) => {
    if (value === "name" || value === "title" || value === "tags") {
      setSortData([`${value}:${key}`]);
    } else {
      setSortData([`${value}:${key}`]);
    }
  }, []);

  const handleClear = useCallback(() => {
    setFilters({});
    history.push("/file-document");
  }, [history]);

  //Apply filters function:
  const handleFiltersSubmit = useCallback(fields => {
    setPage(0);
    setFilters({ ...fields });
  }, []);

  const handleRowPerChange = value => {
    setRowsPerPage(value);
  };
  return (
    <Authenticated>
      <Layout
        title="Lab Document List"
        head={
          <FileDocumentFilterForm
            handleClear={handleClear}
            callback={handleFiltersSubmit}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <FileDocumentList
            sortFilter={sortFilter}
            data={fileLabDocumentList}
            deleteCallback={handleDeleteCalllback}
          />

          {rowsInTotal > rowsPerPage && (
            <Pagination
              page={page}
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              callback={newPage => setPage(newPage)}
              handleRowPerChange={handleRowPerChange}
            />
          )}
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default FileDocumentPage;
