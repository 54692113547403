import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Loader from "../../components/Loader";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import history from "../../history";
import ChatList from "../../components/ChatList/ChatList";
import { countChatQuery, GetChats } from "../../api/_queries";
import "./ChatPage.css";
import { DeleteChatAdmin } from "../../api/_mutations";
import ApolloCacheUpdater from "apollo-cache-updater";
import { useSnackbar } from "notistack";
import Pagination from "../../components/Pagination";
import ChatFilterForm from "../../forms/ChatFilterForm/ChatFilterForm";

const ChatPage = () => {
  const { enqueueSnackbar } = useSnackbar();

  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sortData, setSortData] = useState(["_id:desc"]);

  const [deleteChat] = useMutation(DeleteChatAdmin, {});

  const parseFilters = useMemo(() => {
    const { topic } = filters;

    const escapeString = (str) => {
      if (!str) return "";
      return str.replace(/([\\!@#$%^&*()\[\];{}?,])/g, "\\$1");
    };

    const searchKeywordTopic = topic ? escapeString(topic) : "";

    return {
      ...(topic && { searchText: searchKeywordTopic })
    };
  }, [filters]);

  const {
    data: { getChats: chatList = [], countChats: rowsInTotal } = {},
    loading,
    error,
    refetch
  } = useQuery(GetChats, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters && Object.keys(filters).length ? parseFilters  : {})
      }
    },
    fetchPolicy: 'no-cache'
  });

  const handleDeleteCalllback = useCallback(async (id, topic) => {
    deleteChat({
      variables: { _id: id },
      update: (proxy) => {
        const update = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [GetChats,countChatQuery],
          searchVariables: {},
          operator: "ANY",
          operation: "REMOVE",
          mutationResult: { _id: id },
          ID: "_id",
        });

        if (update) {
          enqueueSnackbar(`Chat ${topic} has been deleted.`, { variant: "success" });
        }
      },
    });
    refetch().catch((e) => {
      console.error("Delete chat error:", e);
      enqueueSnackbar("Cannot delete chat.", { variant: "error" });
    });
  }, [page, rowsPerPage, filters, parseFilters]);

  const handleClear = useCallback(() => {
    setFilters({});
    history.push("/chatList");
  }, [history]);

  const handleFiltersSubmit = useCallback(fields => {
    setPage(0);
    setFilters({ ...fields });
  }, []);

  const handleRowPerChange = (value) => {
    setRowsPerPage(value);
  };

  return (
    <Authenticated>
      <Layout
        title="Chat Q&A List"
        head={
          <ChatFilterForm
            handleClear={handleClear}
            callback={handleFiltersSubmit}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <ChatList
            data={chatList}
            deleteCallback={handleDeleteCalllback}
          />

          {rowsInTotal > rowsPerPage && (
            <Pagination
              page={page}
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              callback={newPage => setPage(newPage)}
              handleRowPerChange={handleRowPerChange}
            />
          )}
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default ChatPage;
