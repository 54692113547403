import React, { useState, useRef, useCallback } from 'react';
import classNames from 'classnames';
import { makeStyles } from '@material-ui/styles';
import { DeleteSharp, CloseOutlined } from '@material-ui/icons';
import imageCompression from 'browser-image-compression';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { Button, Dialog, Slider } from '@material-ui/core';
import getCroppedImg from '../utils/imageCrop'; // Ensure you have the utility to handle cropping

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    margin: -10,
  },
  item: {
    maxWidth: '50%',
    minWidth: '50%',
    padding: 10,
    boxSizing: 'border-box',
    [theme.breakpoints.up('md')]: {
      maxWidth: props => `${100 / props.columns}%`,
      minWidth: props => `${100 / props.columns}%`,
    },
  },
  imageWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#f5f5f5',
    padding: 15,
    height: '33vw',
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      height: '20vw',
    },
    [theme.breakpoints.up('lg')]: {
      height: '12vw',
      maxHeight: 300,
    },
  },
  image: {
    maxWidth: '100%',
    maxHeight: '100%',
  },
  delete: {
    position: 'absolute',
    top: 0,
    right: 0,
    lineHeight: 'normal',
    fontSize: 16,
    padding: 5,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'none',
      backgroundColor: '#00000021',
    },
  },
  input: {
    display: 'none',
  },
  label: {
    display: 'flex',
    cursor: 'pointer',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: '#332e19',
  },
  cropContainer: {
    position: 'relative',
    width: '100%',
    height: 400,
    backgroundColor: '#333',
  },
  cropButton: {
    marginTop: theme.spacing(2),
  },
  dialogContent: {
    padding: theme.spacing(4),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
    cursor: 'pointer',
  },
}));

const MultiImageUpload = (props) => {
  const { name, value = [], setValue, columns } = props;
  const classes = useStyles({ columns });

  const [error, setError] = useState('');
  const [openCrop, setOpenCrop] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [cropper, setCropper] = useState(null);
  const fileInputRef = useRef();
  const cropperRef = useRef();

  const handleImageChange = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      const allowedTypes = ['image/png', 'image/jpg', 'image/jpeg', 'image/svg+xml'];
      if (!allowedTypes.includes(file.type)) {
        setError('Unsupported file type. Only PNG, JPG, JPEG, and SVG are allowed.');
        return;
      }
      setError('');
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        setSelectedImage(reader.result);
        setOpenCrop(true);
      };
    }
  };

  const handleCropperReady = () => {
    const cropperInstance = new Cropper(cropperRef.current, {
      aspectRatio: 0,
      autoCropArea: 1,
      zoomable: true,
      scalable: true,
      viewMode: 1,
      ready() {
        setCropper(cropperInstance);
      },
    });
  };


  const handleApplyCrop = () => {
    if (cropper) {
      const croppedCanvas = cropper.getCroppedCanvas();
      croppedCanvas.toBlob((blob) => {
        const croppedImageUrl = URL.createObjectURL(blob);
  
        const updatedValue = [
          ...(Array.isArray(value) ? value : []),  // ✅ Safely handle undefined value
          {
            name: `cropped-${Date.now()}`,
            type: blob.type,
            size: blob.size,
            data: croppedImageUrl,
            dataFile: blob,
          },
        ];
  
        setValue(name, updatedValue, true);  // ✅ Ensure Formik re-renders
        setOpenCrop(false);
        if (fileInputRef.current) fileInputRef.current.value = '';
      }, 'image/jpeg');
    }
  };
  
  
  // const handleApplyCrop = () => {
  //   if (cropper) {
  //     const croppedCanvas = cropper.getCroppedCanvas();
  //     croppedCanvas.toBlob((blob) => {
  //       const croppedImageUrl = URL.createObjectURL(blob);

  //       const updatedValue = [
  //         ...value,
  //         {
  //           name: `cropped-${Date.now()}`, // Generate a unique name for the file
  //           type: blob.type,
  //           size: blob.size,
  //           data: croppedImageUrl, // Use the generated URL for display
  //           dataFile: blob, // Store the Blob for further processing or upload
  //         },
  //       ];

  //       setValue(name, updatedValue);
  //       setOpenCrop(false);
  //       if (fileInputRef.current) {
  //         fileInputRef.current.value = '';
  //       }
  //     }, 'image/jpeg');
  //   }
  // };

  const handleCloseCrop = () => {
    setOpenCrop(false);
    setSelectedImage(null);
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  return (
    <div className={classes.root}>
      {value && value.map((item, index) => (
        <div className={classes.item} key={index}>
          <div className={classes.imageWrapper}>
            <img src={item.data || item} className={classes.image} alt={`Uploaded file ${index}`} />
            <div
              className={classes.delete}
              onClick={() => setValue(name, value.filter((_, subIndex) => subIndex !== index), true)}
              // onClick={() => setValue(name, value.filter((_, subIndex) => subIndex !== index))}
            >
              <DeleteSharp />
            </div>
          </div>
        </div>
      ))}
      <div className={classes.item}>
        <div className={classes.imageWrapper}>
          <input
            ref={fileInputRef}
            id={name}
            multiple
            type="file"
            accept="image/*"
            className={classes.input}
            onChange={handleImageChange}
          />
          <label htmlFor={name} className={classNames(classes.imageWrapper, classes.label)}>
            Add more
          </label>
          {/* Cropper Modal */}
          <Dialog open={openCrop} onClose={handleCloseCrop} maxWidth="md" fullWidth>
            <div className={classes.closeButton} onClick={handleCloseCrop}>
              <CloseOutlined />
            </div>
            <div className={classes.dialogContent}>
              <div className={classes.cropContainer}>
                <img
                  src={selectedImage}
                  alt="Selected"
                  ref={cropperRef}
                  style={{ width: '100%', height: '100%' }}
                  onLoad={handleCropperReady}
                />
              </div>
              <div className={classes.controls}>
               
                <Button onClick={handleApplyCrop} color="primary" variant="contained" className={classes.cropButton}>
                  Apply Crop
                </Button>
              </div>
            </div>
          </Dialog>
        </div>
        {error && <div style={{ color: 'red' }}>{error}</div>}
      </div>
    </div>
  );
};

MultiImageUpload.defaultProps = {
  columns: 2,
};

export default MultiImageUpload;
