import React from "react";
import multivalues from "../../settings/multivalues.json";
import LanguageSwitch from "../../components/LanguageSwitch";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import FileDocumentForm from "../../forms/FileDocumentForm/FileDocumentForm";

const FileDocumentNew = () => {
  const [languageIndex, setLanguageIndex] = React.useState(0);

  return (
    <Authenticated>
      <Layout title="Create Lab Document">
        <LanguageSwitch
          languageIndex={languageIndex}
          languages={multivalues.languages}
          setLanguageIndex={setLanguageIndex}
        />
        <FileDocumentForm
          languageIndex={languageIndex}
          languagesTotal={Object.keys(multivalues.languages).length}
        />
      </Layout>
    </Authenticated>
  );
};

export default FileDocumentNew;
