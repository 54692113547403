import React from 'react'
import Table from '../Table'
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from '@material-ui/core';
import ConfirmDelete from '../ConfirmDelete';


const LabDocumentList = ({
    data,
    deleteCallback,
    sortFilter
  }) => {
    
    
  return (
    
    <Table
    data={
        
      data.map(item => (
        {
       
            title:
        item?.title && item?.title || "",

        description:
        item?.description &&  item?.description || "",
          
         services:item?.services &&  item?.services || "",
      

      actions: (
          <div
            style={{ display: "flex", alignItems: "baseline" }}
            className="isApprovedWrapper"
          >
        
            <ButtonGroup size="small" variant="text">
                <Button component={Link} 
                to={`/labDocument/${item?._id}`}
                >
                                Edit
                              </Button>
             

              <ConfirmDelete
                id={item._id}
                name={item.title}
                deleteCallback={deleteCallback}
              />
            </ButtonGroup>
          </div>
        ),
    }))



}
    labels={[
      {
        name: "Title",
        field: "title"
      },
      
      {
        name: "Description",
        field: "description"
      },
      {
        name: "Service",
        field: "services"
      },
      
      {
        name: "Actions",
        field: "actions"
      }
    ]}

    sortFilter={sortFilter}
  />

  )
}

export default LabDocumentList