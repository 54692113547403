import React from 'react';
import Table from '../Table';
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from '@material-ui/core';
import ConfirmDelete from '../ConfirmDelete';

const NewsLetterList = ({
  data,
  deleteCallback,
  sortFilter
}) => {
  console.log(data, "newsletter data");
  
  return (
    <Table
      data={
        data.map((item, index) => ({
          subject: item.subject || "N/A",
          details: item.news && item.news[0]?.details 
            ? item.news[0].details[0].replace(/<\/?p>/g, '') 
            : "N/A",
          findMore: item.find_more || "N/A",
          image: item.news && item.news[0]?.image?.url ? (
            <img 
              src={item.news[0].image.url} 
              alt={`Newsletter ${index + 1}`} 
              style={{ width: '50px', height: '50px', objectFit: 'cover' }}
            />
          ) : "",
          
          actions: (
            <div
              style={{ display: "flex", alignItems: "baseline" }}
              className="isApprovedWrapper"
            >
              <ButtonGroup size="small" variant="text">
                <Button component={Link} to={`/newsletter/${item?._id}`}>
                  Edit
                </Button>
                <ConfirmDelete
                  id={item?._id}
                  name={item.subject || `Newsletter ${index + 1}`}
                  deleteCallback={deleteCallback}
                />
              </ButtonGroup>
            </div>
          ),
        }))
      }
      labels={[
        {
          name: "Subject",
          field: "subject"
        },
        {
          name: "Details",
          field: "details"
        },
        {
          name: "Find More",
          field: "findMore"
        },
        {
          name: "Image",
          field: "image"
        },
        {
          name: "Actions",
          field: "actions"
        }
      ]}
      sortFilter={sortFilter}
    />
  );
};

export default NewsLetterList;