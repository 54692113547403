import React, { useEffect, useState } from "react";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import LanguageSwitch from "../../components/LanguageSwitch";
import multivalues from "../../settings/multivalues.json";
import BlogForm from "../../forms/BlogForm/BlogForm";
import Loader from "../../components/Loader";
import { useLazyQuery } from "@apollo/react-hooks";
import { getChatIdQuery } from "../../api/_queries";
import ChatbotForm from "../../forms/ChatbotForm/ChatbotForm";

const ChatEdit = ({
  match: {
    params: { id }
  }
}) => {
  const [languageIndex, setLanguageIndex] = React.useState(0);
  const [transformedData, setTransformedData] = useState(null);
  const [
    getChatQuery,
    { data: { getChatById: data } = {}, loading, error }
  ] = useLazyQuery(getChatIdQuery, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    getChatQuery({
      variables: {
        id
      },
      skip: !id
    });
  }, [id]);

  // useEffect(() => {
  //   if (data) {
  //     const transformed = {
  //       ...data,
  //       tags: Array.isArray(data.tags) ? data.tags[0] : data.tags,
  //       blogimage: data.blog_profile ? 
  //       (data.blog_profile.url || data.blog_profile._id || data.blog_profile) 
  //       : null,
  //       information: data.information.map(item => ({
  //         details: Array.isArray(item.details) ? item.details : [item.details],
  //         image: item.fileUrl ? 
  //           (typeof item.fileUrl === 'string' ? item.fileUrl : item.fileUrl.url) 
  //           : null,
  //         __typename: item.__typename
  //       }))
  //     };
  //     setTransformedData(transformed);
  //   }
  // }, [data]);

  return (
    <Authenticated>
      <Layout title="Edit Blog">
        <Loader>
          <>
            <LanguageSwitch
              languageIndex={languageIndex}
              setLanguageIndex={setLanguageIndex}
              languages={multivalues.languages}
            />
            <ChatbotForm
              data={data}
              isEnableReinitialize={true}
              languageIndex={languageIndex}
              languagesTotal={Object.keys(multivalues.languages).length}
            />
          </>
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default ChatEdit;