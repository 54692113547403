import React from 'react'
import multivalues from '../../settings/multivalues.json'
import LanguageSwitch from '../../components/LanguageSwitch'
import CohortForm from '../../forms/CohortForm/CohortForm'
import Authenticated from '../../containers/Authenticated'
import Layout from '../../components/Layout'

const CohortNew = () => {
    const [languageIndex, setLanguageIndex] = React.useState(0)

    return (
      <Authenticated>
        <Layout title='Create Cohort'>
          <LanguageSwitch
            languageIndex={languageIndex}
            languages={multivalues.languages}
            setLanguageIndex={setLanguageIndex}
          />
          <CohortForm
            languageIndex={languageIndex}
            languagesTotal={Object.keys(multivalues.languages).length}
          />
        </Layout>
      </Authenticated>
    )
}

export default CohortNew