import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "@apollo/react-hooks";
import Loader from "../../components/Loader";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import CohortFilterForm from "../../forms/CohortFilterForm/CohortFilterForm";
import history from "../../history";
import BlogList from "../../components/BlogList/BlogList";
import { countBlogQuery, countCohortQuery, getBlogData, getCohortsData } from "../../api/_queries";
import "./BlogPage.css";
import { deleteBlogMutation, deleteCohortMutation } from "../../api/_mutations";
import ApolloCacheUpdater from "apollo-cache-updater";
import { useSnackbar } from "notistack";
import { NearMeSharp } from "@material-ui/icons";
import Pagination from "../../components/Pagination";
import BlogFilterForm from "../../forms/BlogFilerForm/BlogFilterForm";


const BlogPage = () => {
  const { enqueueSnackbar } = useSnackbar()


  const [filters, setFilters] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);
  const [sortData, setSortData] = useState(["_id:desc"]);


  const [deleteBlog] = useMutation(deleteBlogMutation, {})

  const parseFilters = useMemo(() => {
    const { name, title, tags } = filters;

    const escapeString = (str) => {
      if (!str) return "";
      return str
        .replace(/\\/g, "\\\\\\\\")
        .replace(/\!/g, "\\\\!")
        .replace(/\@/g, "\\\\@")
        .replace(/\#/g, "\\\\#")
        .replace(/\$/g, "\\\\\\$")
        .replace(/\%/g, "\\\\%")
        .replace(/\^/g, "\\\\^")
        .replace(/\&/g, "\\\\&")
        .replace(/\*/g, "\\\\*")
        .replace(/\)/g, "\\\\)")
        .replace(/\(/g, "\\\\(")
        .replace(/\[/g, "\\\\[")
        .replace(/\]/g, "\\\\]")
        .replace(/\;/g, "\\\\;")
        .replace(/\{/g, "\\\\{")
        .replace(/\}/g, "\\\\}")
        .replace(/\?/g, "\\\\?")
        .replace(/\,/g, "\\\\,");
    };

    const searchKeywordName = name ? escapeString(name) : "";
    const searchKeywordTitle = title ? escapeString(title) : "";
    const searchKeywordTags = tags ? escapeString(tags) : "";

    return {
      ...(name && { name_contains: searchKeywordName }),
      ...(title && { title_contains: searchKeywordTitle }),
      ...(tags && { tags_contains: searchKeywordTags })
    };
  }, [filters]);

  const {
    data: { getBlogs: blogsList = [], countBlogs: rowsInTotal } = {},
    loading,
    error,
    refetch
  } = useQuery(getBlogData, {
    variables: {
      params: {
        sort: sortData,
        limit: rowsPerPage,
        skip: page * rowsPerPage,
        ...(filters &&
          Object.keys(filters).length &&
          Object.keys(parseFilters).length
          ? { where: parseFilters }
          : {})
      }
    },
    fetchPolicy: 'no-cache'
  });


  //Delete function:
  // Delete function with improved refetch mechanism

  const handleDeleteCalllback = useCallback(async (id, name) => {
    deleteBlog({
      variables: {
        id,
      },
      update: (proxy) => {
        const update = ApolloCacheUpdater({
          proxy,
          queriesToUpdate: [getBlogData, countBlogQuery],
          searchVariables: {
            params: {
              sort: sortData,
              limit: rowsPerPage,
              skip: page * rowsPerPage,
              ...(filters && Object.keys(filters).length && Object.keys(parseFilters).length
                ? { where: parseFilters }
                : {}
              ),
            }
          }, // Add searchVariables that match your query variables
          operator: "ANY",
          operation: "REMOVE",
          mutationResult: { _id: id },
          ID: "_id",
        });
        
        if (update) {
          enqueueSnackbar(`Blog ${name} has been deleted.`, { variant: "success" });
        }
      },
    })
    refetch()
      .then(() => {
        // Additional success handling if needed
      })
      .catch((e) => {
        console.error("Delete blog error:", e);
        enqueueSnackbar("Cannot delete Blog.", { variant: "error" });
      });
  }, [page, rowsPerPage, filters, parseFilters]);




  const sortFilter = useCallback((value, key) => {
    if (value === "name" || value === "title" || value === "tags") {
      setSortData([`${value}:${key}`]);
    } else {
      setSortData([`${value}:${key}`]);
    }
  }, []);

  const handleClear = useCallback(() => {
    setFilters({});
    history.push("/blogList");
  }, [history]);

  //Apply filters function:
  const handleFiltersSubmit = useCallback(fields => {
    setPage(0);
    setFilters({ ...fields });
  }, []);

  const handleRowPerChange = (value) => {
    setRowsPerPage(value)

  }
  return (
    <Authenticated>
      <Layout
        title="Blog List"
        head={
          <BlogFilterForm
            handleClear={handleClear}
            callback={handleFiltersSubmit}
          />
        }
      >
        <Loader loading={loading} error={error}>
          <BlogList
            data={blogsList}
            sortFilter={sortFilter}
            deleteCallback={handleDeleteCalllback}
          />

          {rowsInTotal > rowsPerPage && (
            <Pagination
              page={page}
              count={rowsInTotal}
              rowsPerPage={rowsPerPage}
              callback={newPage => setPage(newPage)}
              handleRowPerChange={handleRowPerChange}
            />
          )}
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default BlogPage;
