import React, { useEffect, useState } from "react";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import LanguageSwitch from "../../components/LanguageSwitch";
import multivalues from "../../settings/multivalues.json";
import Loader from "../../components/Loader";
import { useLazyQuery } from "@apollo/react-hooks";
import FileDocumentForm from "../../forms/FileDocumentForm/FileDocumentForm";
import { getFileLabDocByIdQuery } from "../../api/_queries";

const FileDocumentEdit = ({
  match: {
    params: { id }
  }
}) => {
  const [languageIndex, setLanguageIndex] = React.useState(0);

  const [
      getFileDataIdQuery,
      { data: { getFileLabDocsById: data } = {}, loading, error }
    ] = useLazyQuery(getFileLabDocByIdQuery, {
      fetchPolicy: "no-cache"
    });
  

  useEffect(() => {
    getFileDataIdQuery({
        variables: { id },
        skip: !id
      });
    }, [id]);
  

  return (
    <Authenticated>
      <Layout title="Edit Lab Document">
        <Loader>
          <>
            <LanguageSwitch
              languageIndex={languageIndex}
              setLanguageIndex={setLanguageIndex}
              languages={multivalues.languages}
            />

            <FileDocumentForm
               data={data}
              languageIndex={languageIndex}
              languagesTotal={Object.keys(multivalues.languages).length}
            />
          </>
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default FileDocumentEdit;
