import React, { useEffect, useState } from "react";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import LanguageSwitch from "../../components/LanguageSwitch";
import multivalues from "../../settings/multivalues.json";
import BlogForm from "../../forms/BlogForm/BlogForm";
import Loader from "../../components/Loader";
import { useLazyQuery } from "@apollo/react-hooks";
import { getBlogbyIdQuery, getNewsLetterbyIdQuery } from "../../api/_queries";
import NewsLetterForm from "../../forms/NewsLetterForm/NewsLetterForm";

const NewsLetterEdit = ({
  match: {
    params: { id }
  }
}) => {
  const [languageIndex, setLanguageIndex] = React.useState(0);
  const [transformedData, setTransformedData] = useState(null);
  const [
    getCohortQuery,
    { data: { getNewssById: data } = {}, loading, error }
  ] = useLazyQuery(getNewsLetterbyIdQuery, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    getCohortQuery({
      variables: {
        id
      },
      skip: !id
    });
  }, [id]);

  useEffect(() => {
    if (data) {
      const transformed = {
        find_more: data.find_more || '',
        subject: Array.isArray(data.subject) ? data.subject : data.subject ? [data.subject] : [],
        news:  data.news.map(item => ({
              details: Array.isArray(item.details) 
                ? item.details 
                : [item.details],
              image: item.image 
                ? { 
                    dataFile: null, 
                    name: item.image.url ? item.image.url.split('/').pop() : 'existing-image',
                    type: 'image/jpeg', // Default type, adjust as needed
                    url: item.image.url || item.image,
                    __typename: item.image.__typename || 'NewsFile'
                  } 
                : null
            }))
         ,
        _id: data._id || null,
        __typename: data.__typename || 'NewsLetterData'
      };
  
      console.log(transformed,"transformed")
      setTransformedData(transformed);
    }
  }, [data]);

  return (
    <Authenticated>
      <Layout title="Edit Blog">
        <Loader>
          <>
            <LanguageSwitch
              languageIndex={languageIndex}
              setLanguageIndex={setLanguageIndex}
              languages={multivalues.languages}
            />
            <NewsLetterForm
              data={transformedData}
              isEnableReinitialize={true}
              languageIndex={languageIndex}
              languagesTotal={Object.keys(multivalues.languages).length}
            />
          </>
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default NewsLetterEdit;