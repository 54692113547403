import React from 'react';
import Table from '../Table';
import { Link } from "react-router-dom";
import { Button, ButtonGroup } from '@material-ui/core';
import ConfirmDelete from '../ConfirmDelete';

const BlogList = ({
  data,
  deleteCallback,
  sortFilter
}) => {
  console.log(data, "blog data");
  
  return (
    <Table
      data={
        data.map(item => ({
          // Map fields based on the data structure
          name: Array.isArray(item?.name) ? item.name.join(", ") : item?.name || "",
          title: Array.isArray(item?.title) ? item.title.join(", ") : item?.title || "",
          
          time: item?.time || "", // Simply use the time field directly as text
          
          tags: Array.isArray(item?.tags) ? item.tags.join(", ") : "",
          slug: item?.slug || "",
          
          // Adding actions column
          actions: (
            <div
              style={{ display: "flex", alignItems: "baseline" }}
              className="isApprovedWrapper"
            >
              <ButtonGroup size="small" variant="text">
                <Button component={Link} to={`/blog/${item?._id}`}>
                  Edit
                </Button>
                <ConfirmDelete
                  id={item?._id}
                  name={Array.isArray(item.title) ? item.title[0] : item.title}
                  deleteCallback={deleteCallback}
                />
              </ButtonGroup>
            </div>
          ),
        }))
      }
      labels={[
        {
          name: "Name",
          field: "name"
        },
        {
          name: "Title",
          field: "title"
        },
     
        {
          name: "Read Time",
          field: "time"
        },
        
        {
          name: "Services",
          field: "tags"
        },
        {
          name: "Actions",
          field: "actions"
        }
      ]}
      sortFilter={sortFilter}
    />
  );
};

export default BlogList;