import React, { useEffect } from "react";
import Authenticated from "../../containers/Authenticated";
import Layout from "../../components/Layout";
import LanguageSwitch from "../../components/LanguageSwitch";
import multivalues from "../../settings/multivalues.json";
import CohortForm from "../../forms/CohortForm/CohortForm";
import Loader from "../../components/Loader";
import { useLazyQuery } from "@apollo/react-hooks";
import { getCohortbyIdQuery } from "../../api/_queries";

const CohortEdit = ({
  match: {
    params: { id }
  }
}) => {
  const [languageIndex, setLanguageIndex] = React.useState(0);
  const [
    getCohortQuery,
    { data: { getCohortsById: data } = {}, loading, error }
  ] = useLazyQuery(getCohortbyIdQuery, {
    fetchPolicy: "no-cache"
  });

  useEffect(() => {
    getCohortQuery({
      variables: {
        id
      },
      skip: !id
    });
  }, [id]);

  console.log(data, "dhruviii");
  return (
    <Authenticated>
      <Layout title="Edit Cohort">
        <Loader>
          {/* {data && */}
          <>
            <LanguageSwitch
              languageIndex={languageIndex}
              setLanguageIndex={setLanguageIndex}
              languages={multivalues.languages}
            />
            <CohortForm
              data={data}
              languageIndex={languageIndex}
              languagesTotal={Object.keys(multivalues.languages).length}
            />
          </>
          {/* } */}
        </Loader>
      </Layout>
    </Authenticated>
  );
};

export default CohortEdit;
